import Home from './components/Home';
import Search from './components/Search';
import Bill from './components/Bill';
import Overview from './components/Overview';
import FAQ from './components/FAQ';
import ThankYou from './components/Thank-You';


const routes = [
    { path: '/', redirect: '/elections/home'},
    { path: '/:topic', redirect: '/:topic/home'},
    { path: '/:topic/home', component: Home },
    { path: '/:topic/overview', component: Overview },
    { path: '/:topic/search', component: Search },
    { path: '/:topic/bill', component: Bill },
    { path: '/:topic/bill/:id', component: Bill },
    { path: '/:topic/faq', component: FAQ },

    // Update 'thank you pages' after ESG goes public:
    { path: '/thank-you', redirect: '/elections/thank-you' },
    { path: '/:topic/thank-you', component: ThankYou },

    // The following are redirects to handle old URL structure 
    // for anyone who may have bookmarked the App links before we moved to new URL structure.  
    { path: '/home', redirect: '/elections/home' }, 
    { path: '/search', redirect: '/elections/search' }, 
    { path: '/overview', redirect: '/elections/overview' }, 
    { path: '/faq', redirect: '/elections/faq' }, 
    { path: '/bill/:id', redirect: '/elections/bill/:id' }, 


]

export default routes;
