import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import routes from './routes'
import VueGtag from "vue-gtag";
import VueSocialSharing from 'vue-social-sharing'
import VueDrift from 'vue-drift'


Vue.use(VueGtag, {
  config: { id: "G-9JLTCYVB0S" }
});


Vue.use(VueRouter);

const router = new VueRouter(
  {
    scrollBehavior(to, from) {

      if (to.path.includes('search') && !from.path.includes('overview')) {
        // Maintain position when changing search

      } else if (to.hash) {
        return {
          selector: to.hash,
          behavior: 'smooth'
        }
       } else {
        return { x: 0, y: 0 };
      }

    },
    routes,
    mode: 'history',
  }
);



// On app load, get the topic area and set as global variable to use through the payload calls
const setupConfig = () => {

  const isEmbed = new URLSearchParams(window.location.search).get('embed') === 'true'; // Hide Drift when app is embedded. 
  const topicPath = window.location.pathname.split('/')[1];
  const whiteLabelTrackers = ['esg', 'china'];

  Vue.prototype.$isBallotpediaTracker = !whiteLabelTrackers.includes(topicPath);

  switch(topicPath) {
    case 'elections':
      Vue.prototype.$topic = 1;
      Vue.prototype.$topicNameFull = 'Election Administration';
      Vue.prototype.$topicPath = 'elections';
      Vue.prototype.$topicNameForTextContent = 'Election';
      Vue.prototype.$whatCountsListID = '3DC725E303A24F8D03D5AC8BEBD48A52';
      Vue.prototype.$trackerLogo = 'EALT_Logo.svg';
      Vue.prototype.$newsletterTitle = 'Ballot Bulletin';
      Vue.prototype.$newsletterSentence = 'Sign up for the Ballot Bulletin, our weekly round-up on election-related legislation delivered straight to your inbox every Friday.';
      Vue.prototype.$customPrimary = '';
      Vue.prototype.$customHeaderColor = '';
      Vue.prototype.$newsletterLink = "https://ballotpedia.org/Ballotpedia%27s_The_Ballot_Bulletin";
      Vue.prototype.$fontFamily = 'Montserrat';
      Vue.prototype.$sessionYears = ['2024', '2023', '2022'];
      if(!isEmbed) {
        Vue.use(VueDrift, {
          appId: 'bxgcu9rra88y',
        });
      }
      break;
    case 'esg':
      Vue.prototype.$topic = 2;
      Vue.prototype.$topicNameFull = 'Environmental, Social, and Governance';
      Vue.prototype.$topicPath = 'esg';
      Vue.prototype.$topicNameForTextContent = 'ESG';
      Vue.prototype.$whatCountsListID = null;
      Vue.prototype.$trackerLogo = 'logo.svg';
      Vue.prototype.$newsletterTitle = null;
      Vue.prototype.$newsletterSentence = null;
      Vue.prototype.$newsletterLink = null;
      Vue.prototype.$customPrimary = '#BE1E2D';
      Vue.prototype.$customHeaderColor = '#FFF';
      Vue.prototype.$fontFamily = 'Montserrat';
      Vue.prototype.$sessionYears = ['2024', '2023', '2022', '2021', '2020'];
      break;
    case 'admin-state':
      Vue.prototype.$topic = 3;
      Vue.prototype.$topicNameFull = 'Administrative State';
      Vue.prototype.$topicPath = 'admin-state';
      Vue.prototype.$topicNameForTextContent = 'Administrative State';
      Vue.prototype.$whatCountsListID = '3DC725E303A24F8D22C71021B47C8210'; 
      Vue.prototype.$trackerLogo = 'ASLT_Logo.svg';
      Vue.prototype.$newsletterTitle = 'Checks and Balances';
      Vue.prototype.$newsletterSentence = 'Sign up for Checks and Balances, our monthly round-up on the administrative state featuring federal and state activity related to the separation of powers, due process, and the rule of law.';
      Vue.prototype.$newsletterLink = "https://ballotpedia.org/Ballotpedia's_Checks_and_Balances";
      Vue.prototype.$customPrimary = '';
      Vue.prototype.$customHeaderColor = '';
      Vue.prototype.$fontFamily = 'Montserrat';
      Vue.prototype.$sessionYears = ['2024'];
      if(!isEmbed) {
        Vue.use(VueDrift, {
          appId: 'bxgcu9rra88y',
        });
      }
      break;
    case 'china':
      Vue.prototype.$topic = 4;
      Vue.prototype.$topicNameFull = 'China and foreign adversary policy';
      Vue.prototype.$topicPath = 'china';
      Vue.prototype.$topicNameForTextContent = 'China and foreign adversary policy';
      Vue.prototype.$whatCountsListID = null;
      Vue.prototype.$trackerLogo = 'logo.svg';
      Vue.prototype.$newsletterTitle = null;
      Vue.prototype.$newsletterSentence = null;
      Vue.prototype.$newsletterLink = null;
      Vue.prototype.$customPrimary = '#171048';
      Vue.prototype.$customHeaderColor = '#FFF';
      Vue.prototype.$fontFamily = 'Merriweather';
      Vue.prototype.$sessionYears = ['2024'];
      break;
    case 'ai-deepfakes':
      Vue.prototype.$topic = 5;
      Vue.prototype.$topicNameFull = 'AI Deepfake';
      Vue.prototype.$topicPath = 'ai-deepfakes';
      Vue.prototype.$topicNameForTextContent = 'AI Deepfake';
      Vue.prototype.$whatCountsListID = null;
      Vue.prototype.$trackerLogo = 'AIDLT_Logo.svg';
      Vue.prototype.$newsletterTitle = null;
      Vue.prototype.$newsletterSentence = null;
      Vue.prototype.$customPrimary = '';
      Vue.prototype.$customHeaderColor = '';
      Vue.prototype.$newsletterLink = null;
      Vue.prototype.$fontFamily = 'Montserrat';
      Vue.prototype.$sessionYears = ['2024', '2023', '2022', '2021', '2020', '2019'];
      break;
    default:
      Vue.prototype.$topic = 1;
      Vue.prototype.$topicNameFull = 'Election Administration';
      Vue.prototype.$topicPath = 'elections';
      Vue.prototype.$topicNameForTextContent = 'Election';
      Vue.prototype.$whatCountsListID = '3DC725E303A24F8D03D5AC8BEBD48A52';
      Vue.prototype.$trackerLogo = 'logo.svg';
      Vue.prototype.$newsletterTitle = 'Ballot Bulletin';
      Vue.prototype.$newsletterSentence = 'Sign up for the Ballot Bulletin, our weekly round-up on election-related legislation delivered straight to your inbox every Friday.';
      Vue.prototype.$customPrimary = '';
      Vue.prototype.$customHeaderColor = '';
      Vue.prototype.$newsletterLink = "https://ballotpedia.org/Ballotpedia%27s_The_Ballot_Bulletin";
      Vue.prototype.$fontFamily = 'Montserrat';
      Vue.prototype.$sessionYears = ['2024', '2023', '2022'];
      if(!isEmbed) {
        Vue.use(VueDrift, {
          appId: 'bxgcu9rra88y',
        });
      }
  }

}


setupConfig();

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')





router.afterEach(to => {

  // After each route change, set global variables
  // This is because the user may go to a different topic area without reloading the app 

  //setupConfig();

  // Update title after each route change

  let title = to.path.substring(1);
  title = title.charAt(0).toUpperCase() + title.slice(1);
  title = title.replaceAll('-', ' ');

  document.title = `${title}`;
});





Vue.use(VueSocialSharing);