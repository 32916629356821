<template>
  <div class="overview-container">



    <button v-if="scrollpx > 300" @click="topFunction()" id="myBtn" title="Go to top">Top</button>

    <div class="intro-container">
      <h2> Overview data </h2>
      <p>Below are several graphics that depict aggregate information about all of the bills we're tracking. Note that on smaller screen sizes, such as phones, the data will display in a table rather than chart. <span v-if="total_bill_count"> We are currently tracking <strong>{{ total_bill_count.toLocaleString() }}</strong> bills.</span> Each graphic is accompanied by a brief description. </p>


      <br/>
      <label>Session year</label>
      <select name="session" @change="changeSession($event)" class="form-control" v-model="session">
        <option v-for="year in this.$sessionYears" :key="year" :value="year">{{year}}</option>
      </select>

      <div class="toc" v-bind:class="{ 'custom': this.$isBallotpediaTracker == false }">
      
        <hr />
        <!-- <p class="toc-header"> Table of contents</p> -->
        <p><a @click="scrollMeTo('status')" class="anchor-tag">
          <strong>{{this.$topicNameForTextContent}}-related bills by legislative status</strong>
        </a></p>

        <p><a @click="scrollMeTo('democratic')" class="anchor-tag">
          <strong>{{this.$topicNameForTextContent}}-related bills by legislative status: Democratic trifectas</strong>
        </a></p>

        <p><a @click="scrollMeTo('republican')" class="anchor-tag">
          <strong>{{this.$topicNameForTextContent}}-related bills by legislative status: Republican trifectas</strong>
        </a></p>

        <p><a @click="scrollMeTo('divided')" class="anchor-tag">
          <strong>{{this.$topicNameForTextContent}}-related bills by legislative status: Divided</strong>
        </a></p>

        <p><a @click="scrollMeTo('sponsor')" class="anchor-tag">
          <strong>{{this.$topicNameForTextContent}}-related bills by partisan affiliation of sponsor(s)</strong>
        </a></p>

        <p><a @click="scrollMeTo('nationwide')" class="anchor-tag">
          <strong>Concentration of {{this.$topicNameForTextContent}}-related legislation activity in the United States</strong>
        </a></p>

        <p><a @click="scrollMeTo('nationwide_enacted')" class="anchor-tag"><strong>Concentration of enacted bills in the United States</strong></a></p>
      </div>

      <hr/>


      <div v-if="this.$isBallotpediaTracker && this.$whatCountsListID !== null">

        <p style="text-align: center; font-size: 16px; padding-bottom: 10px;">Get our round-up on {{this.$topicNameForTextContent}}-related legislation delivered straight to your inbox.</p>
          <div style="text-align: center;">
            <form action="https://whatcounts.com/bin/listctrl" method="POST" style="margin-top: 0px;" class="subscribe-form">
            <input type="hidden" name="slid" :value="this.$whatCountsListID" />
            <input type="hidden" name="cmd" value="subscribe" />
            <input type="hidden" name="wxuiversionfirst" value="" />
            <input type="hidden" name="goto" :value="`https://legislation.ballotpedia.org/${this.$topicPath}/thank-you`" />
            <input type="hidden" name="errors_to" value="" />
            
            <input type="email" id="email" name="email" required maxlength="65" placeholder="Email"
                oninvalid="this.setCustomValidity('Please enter a valid email address')"
                oninput="this.setCustomValidity('')"
                style="width: 250px; margin-right: 10px;"
                />
            <input type="hidden" id="format_mime" name="format" value="mime" />
            <input type="submit" class="btn btn-primary" value="Subscribe" style="width: 100px" />
          </form>
        </div>


        <p v-if="this.$topicPath === 'elections'" style="text-align: center; margin-top: 15px; font-size: 16px;"><a target="_blank" href="https://ballotpedia.org/Election_legislation_tracking:_weekly_digest">Click here to view the weekly round-up archive</a></p>

      </div>

      
      
    </div>

    <div v-if="loading" class="loading-container">
      <div class="lds-dual-ring"></div>
    </div>




  <div v-show="!loading" class="graphs-container" >

    <!-- Graph #1 -->
      <div class="graph-header" ref="status">
        <p class="graph-header-text">{{this.$topicNameForTextContent}}-related bills by legislative status</p>
      </div>
      <div class="intro-container explainer-text">
        <p>This pie chart visualizes the legislative status of the bills we are tracking. The following status indicators are used: </p>
        <br/>
        <ul>
            <li>
                <b>Introduced:</b>
                The bill has been pre-filed, introduced, or referred to committee.
            </li>
            <li>
                <b>Crossed over:
                </b>The bill has passed one chamber of the legislature, or both chambers have passed versions of the bill
                but not approved the same version.
            </li>
            <li>
                <b>Passed both chambers:</b>
                Both chambers of the legislature have passed the same version of the bill.
            </li>
            <li>
                <b>Enacted:</b>
                The bill has become law, by gubernatorial action or inaction.
            </li>
            <li>
                <b>Vetoed:</b>
                The bill has been vetoed.
            </li>
            <li>
                <b>Veto overridden:
                </b>The legislature voted to override the governor’s veto of the bill.
            </li>
            <li>
                <b>Dead:
                </b>The bill has been defeated or failed to advance by certain deadlines.
            </li>
        </ul>
        
      </div>
      <div id="by_status" class="graph-container">

        
      </div>
      <!-- <img src="../../src/assets/images/bp-logo-sm.svg" class="bp-watermark"> -->
      <img src="../../src/assets/images/logo.svg" class="bp-watermark" v-if="this.$isBallotpediaTracker">

      <h3 class="mobile-header">{{this.$topicNameForTextContent}}-related bills by legislative status</h3>
      <div class="table-data-container">
        <table>
          <th>Status</th>
          <th>Number</th>
          <tr v-for="row in status_overview_data" :key="row.category">
            <td>{{row.category}}</td>
            <td>{{row.value }}</td>
          </tr>
        </table>
      </div>



      <!-- Graph #2 -->
      <div class="graph-header" ref="democratic">
        <p class="graph-header-text">{{this.$topicNameForTextContent}}-related bills by legislative status: Democratic trifectas</p>
      </div>
      <div class="intro-container explainer-text">
        <p>This pie chart visualizes the legislative status of bills that have been introduced in states with Democratic trifectas. </p>
      </div>
      <div id="status_by_democratic" class="graph-container"></div>
      <img src="../../src/assets/images/logo.svg" class="bp-watermark" v-if="this.$isBallotpediaTracker">
      
      <h3 class="mobile-header">{{this.$topicNameForTextContent}}-related bills by legislative status: Democratic trifectas</h3>
      <div class="table-data-container">
        <table>
          <th>Status</th>
          <th>Number</th>
          <tr v-for="row in status_by_democratic" :key="row.category">
            <td>{{row.category}}</td>
            <td>{{row.value }}</td>
          </tr>
        </table>
      </div>

       <!-- Graph #3 -->
      <div class="graph-header" ref="republican">
        <p class="graph-header-text">{{this.$topicNameForTextContent}}-related bills by legislative status: Republican trifectas</p>
      </div>
      <div class="intro-container explainer-text">
        <p>This pie chart visualizes the legislative status of bills that have been introduced in states with Republican trifectas.</p>
      </div>
      <div id="status_by_republican" class="graph-container"></div>
      <img src="../../src/assets/images/logo.svg" class="bp-watermark" v-show="this.$isBallotpediaTracker">

      <h3 class="mobile-header">{{this.$topicNameForTextContent}}-related bills by legislative status: Republican trifectas</h3>
      <div class="table-data-container">
        <table>
          <th>Status</th>
          <th>Number</th>
          <tr v-for="row in status_by_republican" :key="row.category">
            <td>{{row.category}}</td>
            <td>{{row.value }}</td>
          </tr>
        </table>
      </div>


       <!-- Graph #4 -->
      <div class="graph-header" ref="divided">
        <p class="graph-header-text">{{this.$topicNameForTextContent}}-related bills by legislative status: Divided</p>
      </div>
      <div class="intro-container explainer-text">
        <p>This pie chart visualizes the legislative status of bills that have been introduced in states with divided governments.</p>
      </div>
      

      <p v-show="!this.response.status_by_divided" class="no-data">No data.</p>
      <div id="status_by_divided" class="graph-container" v-show="this.response.status_by_divided"></div>
            
      <div  v-show="this.response.status_by_divided && this.$isBallotpediaTracker">
        <img src="../../src/assets/images/logo.svg" class="bp-watermark" v-if="this.$isBallotpediaTracker">
      </div>

      <h3 class="mobile-header">{{this.$topicNameForTextContent}}-related bills by legislative status: Divided</h3>
      <div class="table-data-container">
        <table>
          <th>Status</th>
          <th>Number</th>
          <tr v-for="row in status_by_divided" :key="row.category">
            <td>{{row.category}}</td>
            <td>{{row.value }}</td>
          </tr>
        </table>
      </div>

         <!-- Graph #5 -->
      <div class="graph-header" ref="sponsor">
        <p class="graph-header-text">{{this.$topicNameForTextContent}}-related bills by partisan affiliation of sponsor(s)</p>
      </div>
      <div class="intro-container explainer-text">
        <p>The pie chart below visualizes the partisan affiliation of bill sponsors.</p>
      </div>
      <div id="partisan_affiliation" class="graph-container"></div>
      <img src="../../src/assets/images/logo.svg" class="bp-watermark" v-if="this.$isBallotpediaTracker">

      <h3 class="mobile-header">{{this.$topicNameForTextContent}}-related bills by partisan affiliation of sponsor(s)</h3>
      <div class="table-data-container">
        <table>
          <th>Status</th>
          <th>Number</th>
          <tr v-for="row in partisan_affiliation" :key="row.category">
            <td>{{row.category}}</td>
            <td>{{row.value }}</td>
          </tr>
        </table>
      </div>


    <!-- Graph #6 -->
      <div class="graph-header" ref="nationwide">
        <p class="graph-header-text">Concentration of {{this.$topicNameForTextContent}}-related legislation activity in the United States</p>
      </div>
      <div class="intro-container explainer-text">
        <p>The map below visualizes the concentration of legislative activity across the nation for the entire year. A darker shade indicates a higher number of relevant bills that have been introduced. A lighter shade indicates a lower number of relevant bills. </p>
      </div>
      <div id="totals_by_state" class="map-container"></div>
      <img src="../../src/assets/images/logo.svg" class="bp-watermark bp-watermark-map" v-if="this.$isBallotpediaTracker">


      <h3 class="mobile-header">Concentration of {{this.$topicNameForTextContent}}-related legislation activity in the United States</h3>
      <div class="table-data-container">
        <table>
          <th>State</th>
          <th>Number</th>
          <tr v-for="row in nationwide_overview_data" :key="row.category">
            <td>{{row.id.replace('US-', '')}}</td>
            <td>{{row.value }}</td>
          </tr>
        </table>
      </div>



    <!-- Graph #7 -->
      <div class="graph-header" ref="nationwide_enacted">
        <p class="graph-header-text">Concentration of enacted bills in the United States</p>
      </div>
      <div class="intro-container explainer-text">
        <p>The map below visualizes the concentration of enacted bills across the nation for the entire year. A darker shade indicates a higher number of enacted bills. A lighter shade indicates a lower number of enacted bills. </p>
      </div>
      <div id="totals_by_state_enacted" class="map-container" v-show="this.response.totals_by_state_enacted"></div>

      <p class="no-data" v-show="!this.response.totals_by_state_enacted">No data.</p>
      
      <div  v-show="this.response.totals_by_state_enacted && this.$isBallotpediaTracker">
        <img src="../../src/assets/images/logo.svg" class="bp-watermark bp-watermark-map" v-if="this.$isBallotpediaTracker">
      </div>

      <h3 class="mobile-header">Concentration of enacted bills in the United States</h3>
      <div class="table-data-container">
        <table>
          <th>State</th>
          <th>Number</th>
          <tr v-for="row in nationwide_overview_data_enacted" :key="row.category">
            <td>{{row.id.replace('US-', '')}}</td>
            <td>{{row.value }}</td>
          </tr>
        </table>
      </div>



    </div>


    <!-- <div id="chartdiv2" class="graph-container"></div> -->
  </div>
</template>


<script src="https://cdn.amcharts.com/lib/5/geodata/usaLow.js"></script>
<script>
import * as am5 from '@amcharts/amcharts5';
//import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import am5themes_Responsive from '@amcharts/amcharts5/themes/Responsive';
import * as am5percent from "@amcharts/amcharts5/percent";
import * as am5map from "@amcharts/amcharts5/map";
//import * as am5geodata_usaLow from "@amcharts/amcharts5/geodata/usaLow";


export default {
  name: "Overview",
  props: {},

  data: () => {
    return {
      loading: false,
      response: "",
      status_overview_data: [],
      status_by_democratic: [],
      status_by_republican: [],
      status_by_divided: [],
      partisan_affiliation: [],
      nationwide_overview_data: [],
      nationwide_overview_data_enacted: [],
      states: ['AL','AK','AZ','AR','CA','CO','CT','DE','FL','GA',
        'HI','ID','IL','IN','IA','KS','KY','LA','ME','MD','MA',
        'MI','MN','MS','MO','MT','NE','NV','NH','NJ','NM','NY','NC','ND',
        'OH','OK','OR','PA','RI','SC','SD','TN','TX','UT',
        'VT','VA','WA','WV','WI','WY'],

      total_bill_count: null,
      scrollpx: 0,
      session: '',
      roots: []
    };
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
    
    // Set session to current year. 
    this.session = new Date().getFullYear();

  },

  methods: {
    changeSession(e) {
      console.log(e.target.value);
      this.session = e.target.value;
      this.init();

    },
    scrollMeTo(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop - 100;

      window.scrollTo({
        top,
        behavior: 'smooth'
      });
    },
   topFunction() {
            window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
    handleScroll() {
        this.scrollpx = window.scrollY;
        // console.log(this.scrollpx);
    },

    async init() {
      //console.log(this.$topic)
      console.log('mounted', this.session);
  
      this.loading = true;
 

      

      let response = '';
      this.total_bill_count = null;
      this.status_overview_data = [];
      this.status_by_democratic = [];
      this.status_by_republican = [];
      this.status_by_divided = [];
      this.partisan_affiliation = [];
      this.nationwide_overview_data = [];
      this.nationwide_overview_data_enacted = [];



     


      response = await fetch(
        `${process.env.VUE_APP_API4_DOMAIN}/legislation_tracking/overview`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(
            { 
              topic_area: this.$topic,
              session: [this.session.toString()]
            }
        )
        }
      );
      

      response = await response.json();

      if (response && response.success) {

        

        


        this.response = response.data[0].aggregate_data;

        this.total_bill_count = response.data[0].aggregate_data.total_overall[0].count


        console.log(this.response);

        let statusColorMap = {
          'Introduced': 0x0095A4,
          'Crossed over': 0x253979,
          'Passed both chambers': 0x603987,
          'Enacted': 0x7BAD41,
          'Veto Overridden': 0xF0A142,
          'Dead': 0x010101,
          'Vetoed': 0xB12028,
          'Fallback': 0x59595B,
        }
        let getStatusColors = bills => bills.map(({category}) => {
            return am5.color(statusColorMap[category] || statusColorMap.Fallback);
        });

    

        // By Status
        this.response.totals_by_status.forEach((status) => {
          this.status_overview_data.push({
            value: status.count,
            category: status.current_legislative_status,
          });
        });

        am5.addLicense("AM5C327719799");

       
        // Clear all maps if the API call is called again.
        this.roots.forEach((root) => {
          root.dispose();
        })
  
      
  
        let root = am5.Root.new("by_status");

        root.setThemes([am5themes_Responsive.new(root)]);

        let chart = root.container.children.push(
          am5percent.PieChart.new(root, {
            layout: root.verticalLayout,
            radius: am5.percent(50)
          })
        );

        let series = chart.series.push(
          am5percent.PieSeries.new(root, {
            valueField: "value",
            categoryField: "category"
          })
        );

        series.get("colors").set("colors", getStatusColors(this.status_overview_data));

        series.labels.template.setAll({
          fontSize: 16,
          fill: am5.color(0x550000),
          text: "{category}: ({value}) ",
          inside: true,
          radius: 5,
          textType: 'circular'
        });

        series.data.setAll(this.status_overview_data);
        series.appear(1000, 100);

        this.roots.push(root);



        // By Status - Democratic Trifectas
        this.response.status_by_democratic.forEach((status) => {
          this.status_by_democratic.push({
            value: status.count,
            category: status.current_legislative_status,
          });
        });


  
        root = am5.Root.new("status_by_democratic");

        root.setThemes([am5themes_Responsive.new(root)]);

        chart = root.container.children.push(
          am5percent.PieChart.new(root, {
            layout: root.verticalLayout,
            radius: am5.percent(50)
          })
        );

        series = chart.series.push(
          am5percent.PieSeries.new(root, {
            valueField: "value",
            categoryField: "category"
          })
        );

        series.get("colors").set("colors", getStatusColors(this.status_by_democratic));

        series.labels.template.setAll({
          fontSize: 16,
          fill: am5.color(0x550000),
          text: "{category}: ({value}) ",
          inside: true,
          radius: 5,
          textType: 'circular'
        });

        series.data.setAll(this.status_by_democratic);
        series.appear(1000, 100);
        this.roots.push(root);


        // By Status - Republican Trifectas
        this.response.status_by_republican.forEach((status) => {
          this.status_by_republican.push({
            value: status.count,
            category: status.current_legislative_status,
          });
        });

  
        root = am5.Root.new("status_by_republican");

        root.setThemes([am5themes_Responsive.new(root)]);

        chart = root.container.children.push(
          am5percent.PieChart.new(root, {
            layout: root.verticalLayout,
            radius: am5.percent(50)
          })
        );

        series = chart.series.push(
          am5percent.PieSeries.new(root, {
            valueField: "value",
            categoryField: "category"
          })
        );

        series.get("colors").set("colors", getStatusColors(this.status_by_republican));

        series.labels.template.setAll({
          fontSize: 16,
          fill: am5.color(0x550000),
          text: "{category}: ({value}) ",
          inside: true,
          radius: 5,
          textType: 'circular'
        });

        series.data.setAll(this.status_by_republican);
        series.appear(1000, 100);
        this.roots.push(root);


        // By Status - Divided

        if(this.response.status_by_divided) {
          this.response.status_by_divided.forEach((status) => {
            this.status_by_divided.push({
              value: status.count,
              category: status.current_legislative_status,
            });
          });
        }



  
        root = am5.Root.new("status_by_divided");

        root.setThemes([am5themes_Responsive.new(root)]);

        chart = root.container.children.push(
          am5percent.PieChart.new(root, {
            layout: root.verticalLayout,
            radius: am5.percent(50)
          })
        );

        series = chart.series.push(
          am5percent.PieSeries.new(root, {
            valueField: "value",
            categoryField: "category"
          })
        );

        series.get("colors").set("colors", getStatusColors(this.status_by_divided));

        series.labels.template.setAll({
          fontSize: 16,
          fill: am5.color(0x550000),
          text: "{category}: ({value}) ",
          inside: true,
          radius: 5,
          textType: 'circular'
        });

        series.data.setAll(this.status_by_divided);
        series.appear(1000, 100);
        this.roots.push(root);


        // Partisan Affiliation of Sponsors
        this.response.partisan_affiliation.forEach((status) => {
          this.partisan_affiliation.push({
            value: status.count,
            category: status.sponsors_partisan_affiliations,
          });
        });

  
        root = am5.Root.new("partisan_affiliation");

        root.setThemes([am5themes_Responsive.new(root)]);

        chart = root.container.children.push(
          am5percent.PieChart.new(root, {
            layout: root.verticalLayout,
            radius: am5.percent(50)
          })
        );

        series = chart.series.push(
          am5percent.PieSeries.new(root, {
            valueField: "value",
            categoryField: "category"
          })
        );

        const partyColorMap = {
          'Democratic': 0x6984B0,
          'Republican': 0xCF7A7F,
          'Bipartisan': 0x8F76A9,
          'Other': 0x9B9B9D,
        }

        const cats = this.partisan_affiliation.map(({category}) => partyColorMap[category]);
        series.get("colors").set("colors", cats.map(c => am5.color(c)));

        series.labels.template.setAll({
          fontSize: 16,
          fill: am5.color(0x550000),
          inside: true,
          radius: 5,
          textType: 'circular'
        });

        series.slices.template.events.on("click", (event) => {
          //console.log(event)
          //console.log(event.target.adapters._entity._dataItem._settings.category);
        });

      

          


        series.data.setAll(this.partisan_affiliation);
        series.appear(1000, 100);
        this.roots.push(root);

  
        // By State 


        // Create object for count "0" if any of the 50 states not returned from query.
        this.states.forEach((stateName) => {
          var index = this.response.totals_by_state.findIndex(x => x.state == stateName);
          if(index === -1) {
            this.response.totals_by_state.push({ state: stateName, count: 0})
          }
        })


      
        // Transform data for the graph 
        this.response.totals_by_state.forEach((state) => {
          this.nationwide_overview_data.push({
            value: state.count,
            id: `US-${state.state}`,
          });
        });

        this.nationwide_overview_data.sort((a, b) => a.id.localeCompare(b.id))



          root = am5.Root.new("totals_by_state"); 

          am5.addLicense("AM5M327719799");

          // Set themes
          root.setThemes([
            am5themes_Animated.new(root)
          ]);

          // Create chart
          chart = root.container.children.push(am5map.MapChart.new(root, {
            panX: "none",
            panY: "none",
            projection: am5map.geoAlbersUsa(),
            layout: root.horizontalLayout,
            wheelable: false
          }));

          // console.log(chart);




          // Create polygon series
          var polygonSeries = chart.series.push(am5map.MapPolygonSeries.new(root, {
            geoJSON: am5geodata_usaLow,
            valueField: "value",
            calculateAggregates: true,
            wheelable: false
          }));


          polygonSeries.mapPolygons.template.setAll({
            tooltipText: "{name}: {value}",
            wheelable: false
          });

          chart.chartContainer.wheelable = false;

          polygonSeries.set("heatRules", [{
            target: polygonSeries.mapPolygons.template,
            dataField: "value",
            min: am5.color(0xC4E1EC),
            max: am5.color(0x1E6E88),
            key: "fill",
            wheelable: false
          }]);

          polygonSeries.mapPolygons.template.events.on("pointerover", function(ev) {
            heatLegend.showValue(ev.target.dataItem.get("value"));
          });

          polygonSeries.data.setAll(this.nationwide_overview_data);

          var heatLegend = chart.children.push(am5.HeatLegend.new(root, {
            orientation: "vertical",
            startColor: am5.color(0xC4E1EC),
            endColor: am5.color(0x1E6E88),
            startText: "Lowest",
            endText: "Highest",
            stepCount: 5,
            wheelable: false
          }));

          heatLegend.startLabel.setAll({
            fontSize: 12,
            fill: heatLegend.get("startColor")
          });

          heatLegend.endLabel.setAll({
            fontSize: 12,
            fill: heatLegend.get("endColor")
          });

          // change this to template when possible
          polygonSeries.events.on("datavalidated", function () {
            heatLegend.set("startValue", polygonSeries.getPrivate("valueLow"));
            heatLegend.set("endValue", polygonSeries.getPrivate("valueHigh"));
          });

          chart._settings.maxZoomLevel = 1;
          this.roots.push(root);



      // By State by Status of "Enacted"


      // Create object for count "0" if any of the 50 states not returned from query. 


      if(this.response.totals_by_state_enacted) {
        this.states.forEach((stateName) => {
          var index = this.response.totals_by_state_enacted.findIndex(x => x.state == stateName);
          if(index === -1) {
            this.response.totals_by_state_enacted.push({ state: stateName, count: 0})
          }
        })
      }




      if(this.response.totals_by_state_enacted) {
        // Transform data for the graph 
        this.response.totals_by_state_enacted.forEach((state) => {
          this.nationwide_overview_data_enacted.push({
            value: state.count,
            id: `US-${state.state}`,
          });
        });

        this.nationwide_overview_data_enacted.sort((a, b) => a.id.localeCompare(b.id))

      } else {
        this.states.forEach((stateName) => {
          this.nationwide_overview_data_enacted.push({
            value: 0,
            id: `US-${stateName}`,
          });
        })
      }


          root = am5.Root.new("totals_by_state_enacted"); 

          am5.addLicense("AM5M327719799");

          // Set themes
          root.setThemes([
            am5themes_Animated.new(root)
          ]);

          // Create chart
          chart = root.container.children.push(am5map.MapChart.new(root, {
            panX: "none",
            panY: "none",
            projection: am5map.geoAlbersUsa(),
            layout: root.horizontalLayout,
            wheelable: false
          }));

          // console.log(chart);




          // Create polygon series
          var polygonSeries = chart.series.push(am5map.MapPolygonSeries.new(root, {
            geoJSON: am5geodata_usaLow,
            valueField: "value",
            calculateAggregates: true,
            wheelable: false
          }));


          polygonSeries.mapPolygons.template.setAll({
            tooltipText: "{name}: {value}",
            wheelable: false
          });

          chart.chartContainer.wheelable = false;

          polygonSeries.set("heatRules", [{
            target: polygonSeries.mapPolygons.template,
            dataField: "value",
            min: am5.color(0xC4E1EC),
            max: am5.color(0x1E6E88),
            key: "fill",
            wheelable: false
          }]);

          polygonSeries.mapPolygons.template.events.on("pointerover", function(ev) {
            heatLegend.showValue(ev.target.dataItem.get("value"));
          });

          polygonSeries.data.setAll(this.nationwide_overview_data_enacted);

          var heatLegend = chart.children.push(am5.HeatLegend.new(root, {
            orientation: "vertical",
            startColor: am5.color(0xC4E1EC),
            endColor: am5.color(0x1E6E88),
            startText: "Lowest",
            endText: "Highest",
            stepCount: 5,
            wheelable: false
          }));

          heatLegend.startLabel.setAll({
            fontSize: 12,
            fill: heatLegend.get("startColor")
          });

          heatLegend.endLabel.setAll({
            fontSize: 12,
            fill: heatLegend.get("endColor")
          });

          // change this to template when possible
          polygonSeries.events.on("datavalidated", function () {
            heatLegend.set("startValue", polygonSeries.getPrivate("valueLow"));
            heatLegend.set("endValue", polygonSeries.getPrivate("valueHigh"));
          });

          chart._settings.maxZoomLevel = 1;
          this.roots.push(root);




        // end loading
        this.loading = false;


      } else {
        this.apiError = true;
        this.loading = false;
      }
    }
  },



  
  async mounted() {
    this.init();

  },

};





</script>


<style scoped>

.esg a {
  color: #BE1E2D !important;
}

.btn {
  padding: unset;
  background: #337ab7;
  color: white;
  border: none;
}

.bp-watermark {
  width: 125px;
  margin-left: 30%;
  position: relative;
  bottom: 100px;
}
.bp-watermark-map {
  bottom: 0px;
}
.toc {
  font-size: 16px;
  margin-top: 20px;
}
.toc-header {
  font-weight: 600;
  text-decoration: underline;
}
.anchor-tag {
  cursor: pointer;
}
.explainer-text {
  margin-top: 20px;
}
.overview-container {
  color: #444444;
  margin-top: 150px;
}
.intro-container {
  margin-left: 20%;
  margin-right: 20%;
  max-width: 900px;
  margin-right: auto;
  margin-left: auto;
}
.loading-container {
  text-align: center;
  margin-top: 50px;
}
.graph-header {
  margin-top: 50px;
  max-height: 45px;
  display: flex;
  align-content: center;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 5px;
  background: #e2eeff;
  background: #EDEDED;
  width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.graph-header-text {
  border-left: 15px solid #3047b1;
  height: 55px;
  display: flex;
  align-items: center;
  margin-left: 20px;
  padding-left: 20px;
  font-size: 20px;
  font-weight: 700;
}

.graph-header-text-esg {
  border-left: 15px solid #BE1E2D;
}

.graph-container {
  height: 500px;
}

.map-container {
  height: 500px;
  width: 800px; 
  display: block; 
  margin: 0 auto; 
  margin-top: 100px;
}

.table-data-container {
  display: none;
}

.table-data-container {
  margin-top: 30px;
}

.mobile-header {
  display: none;
  margin-top: 100px;
}

.table-data-container table {
  width: 100%;
}

@media screen and (max-width: 767px) {

  .toc {
    display: none;
  }
  .graph-container, .map-container, .explainer-text {
    display: none;
  }

  .bp-watermark {
    display: none;
  }

  .table-data-container {
    display: block;
  }
  .intro-container {
    margin-left: 0;
    margin-right: 0;
  }
  .graph-header {
    display: none;
  }
  .mobile-header {
    display: block;
  }
  .overview-container {
    margin-top: 100px;
    margin-left: 20px;
    margin-right: 20px;
  }
}

h2 {
  text-align: center;
}
#myBtn {
  /* display: none; */
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: #3047B1;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 4px;
}

#myBtn:hover {
  background-color: #555;
}

.no-data {
  padding-top: 40px;
  font-weight: 700;
  text-align: center;
}
</style>
