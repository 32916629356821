<template>
    <div class="faq-container">
        <h1>FAQ</h1>
        <p class="home-tagline">Frequently Asked Questions</p>

        <div class="video-container" style="text-align: center" v-if="this.$topicPath === 'elections' || this.$topicPath === 'ai-deepfakes' || this.$topicPath === 'admin-state'">
            <p class="bold" style="text-align: center; margin-bottom: 10px;"> Watch the walk through video here: </p>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/i6zvm4Imxns" title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
        </div>

        <div v-if="this.$topicPath === 'ai-deepfakes'" >
            <br>
        <p class="intro-p"><i>Deepfakes</i> are videos, images, or audio files that have been generated or manipulated by artificial intelligence in order to realistically portray something that did not actually occur. According to the U.S. Government Accountability Office, “Deepfakes rely on artificial neural networks, which are computer systems modeled loosely on the human brain that recognize patterns in data. Developing a deepfake photo or video typically involves feeding hundreds or thousands of images into the artificial neural network, ‘training’ it to identify and reconstruct patterns — usually faces.”<sup>1</sup></p>
        <p class="intro-p">Public policy organizations, lawmakers, and journalists have expressed concerns related to the use of deepfake technology, including the proliferation of child sexual abuse material and other nonconsensual sexual content, the distribution of deceptive political communications and election disinformation, property rights infringement, harassment, fraud, and threats to national security.<sup>2, 3, 4, 5, 6, 7, 8</sup></p>
        <p class="intro-p">Policy approaches vary as lawmakers and advocates balance concerns about deepfakes with First Amendment free speech considerations and beneficial uses of the technology.<sup>1, 9, 10</sup> The Foundation for Individual Rights and Expression writes, "Any government restriction on the expressive use of AI needs to be narrowly tailored to serve a compelling governmental purpose, and the regulation must restrict as little expression as is necessary to achieve that purpose."<sup>11</sup></p>
        <p class="intro-p">As legislatures across the country consider bills intended to address concerns about deepfake technology, Ballotpedia’s <b>AI deepfake legislation tracker</b> can help you follow the latest developments in all 50 states.</p>
        <p class="intro-p">See the answers below for more information about using the tracker.</p>
      </div>



        <p class="bold">Where does your bill information come from?</p>
        <p v-if="this.$topicPath != 'ai-deepfakes'">We get our raw bill information from BillTrack50, which aggregates legislative data from all 50 states and
            presents it in a standardized format. We take that information, analyze it, apply category tags, and summarize
            the bill content.</p>
        <p v-if="this.$topicPath === 'ai-deepfakes'">We get our bill information from BillTrack50, which aggregates legislative data from all 50 states and presents it in a standardized format. We then review each relevant bill and add category tags based on the bill’s content. </p>

        <p class="bold">What do the bill status indicators mean?</p>
        <p>Each bill in our database has been assigned one of the following legislative status indicators:</p>
        <ul>
            <li>
                <b>Introduced:</b>
                The bill has been pre-filed, introduced, or referred to committee.
            </li>
            <li>
                <b>Crossed over:
                </b>The bill has passed one chamber of the legislature, or both chambers have passed versions of the bill
                but not approved the same version.
            </li>
            <li>
                <b>Passed both chambers:</b>
                Both chambers of the legislature have passed the same version of the bill.
            </li>
            <li>
                <b>Enacted:</b>
                The bill has become law, by gubernatorial action or inaction.
            </li>
            <li>
                <b>Vetoed:</b>
                The bill has been vetoed.
            </li>
            <li>
                <b>Veto overridden:
                </b>The legislature voted to override the governor’s veto of the bill.
            </li>
            <li>
                <b>Dead:
                </b>The bill has been defeated or failed to advance by certain deadlines.
            </li>
        </ul>

        <p class="bold">What does "Most recent action" mean?</p>
        <p>Each bill record contains a description of the most recent action taken on that bill and when it happened. Only
            the most recent action is noted.</p>
        <p>You can find a bill's complete legislative history on BillTrack50, linked on each bill page.</p>

        <p class="bold" id="definition">What do the category tags mean?</p>
        <p>Every bill in our database has been assigned at least one category tag. These tags, and their definitions, are
            listed below.</p>
        <p v-if="this.$topicPath === 'elections'"><em><b>Note:</b></em>&nbsp;
            Our list of bill categories has evolved since we launched the legislation tracker in 2022. Generally, we have
            not applied tags retroactively to bills in previous sessions. Please
            <a target="_blank" href="mailto:editor@ballotpedia.org">contact us</a>
            if you have questions about certain tags.
        </p>
        <p v-if="this.$topicPath === 'admin-state'">Please
            <a target="_blank" href="mailto:editor@ballotpedia.org">contact us</a>
            if you have questions about certain tags.
        </p>
        <br/>
        <div v-if="this.$topicPath === 'elections'">
        <ol>
            <li>
                <b>Absentee/mail-in voting</b>
            <ol>
                <li>
                    <b>Absentee/mail-in ballot administration:</b>
                    Any bill concerning the administration of the absentee/mail-in voting process, such as dates for
                    transmitting ballots.
                </li>
                <li>
                    <b>Absentee/mail-in ballot deadlines:</b>
                    Any bill that changes the deadline for voters to request or return absentee/mail-in ballots.
                </li>
                <li>
                    <b>Absentee/mail-in ballot request processes:</b>
                    Any bill that changes how voters can request absentee/mail-in ballots.
                </li>
                <li>
                    <b>Absentee/mail-in ballot return processes:</b>
                    Any bill that changes how voters can return absentee/mail-in ballots. This category does not include
                    bills related to third-party ballot collection (see “ballot collection”).
                </li>
                <li>
                    <b>Absentee/mail-in voting eligibility:</b>
                    Any bill that either expands or restricts absentee/mail-in voting eligibility, including any excuse
                    requirement to vote by absentee/mail-in ballot.
                </li>
                <li>
                    <b>Automatic mail-in ballots:</b>
                    Any bill concerning an automatic mail-in ballot voting system in which officials automatically send
                    mail-in ballots to all eligible voters.
                </li>
                <li>
                    <b>Drop-box availability:</b>
                    Any bill that regulates the allowance, availability, or placement of ballot drop-boxes.
                </li>
                <li>
                    <b>Permanent absentee/mail-in ballot lists:</b>
                    Any bill regarding the creation, maintenance, or elimination of a permanent absentee/mail-in voter list.
                </li>
            </ol>
            </li>
            <li>
                <b>Alternative voting methods</b>
            <ol>
                <li>
                    <b>Online voting:</b>
                    Any bill that addresses internet voting.
                </li>
                <li>
                    <b>Proportional representation voting</b>: Any bill addressing proportional representation voting. Under
                    this system, the number of votes a party’s candidates win in an election determines the number of seats
                    that party will control in the legislature.
                </li>
                <li>
                    <b>RCV - Allow RCV:</b>
                    Any bill allowing (but not requiring) the use of ranked-choice voting in one or more elections.
                </li>
                <li>
                    <b>RCV - Amends RCV laws:</b>
                    Any bill amending existing laws on ranked-choice voting (but not repealing or prohibiting ranked-choice
                    voting in a place where it is already allowed or required).
                </li>
                <li>
                    <b>RCV - Prohibit RCV:</b>
                    Any bill prohibiting ranked-choice voting in one or more elections.
                </li>
                <li>
                    <b>RCV - Repeal RCV:</b>
                    Any bill repealing existing laws requiring or allowing ranked-choice voting in one or more elections.
                </li>
                <li>
                    <b>RCV - Require RCV:</b>
                    Any bill requiring the use of ranked-choice voting in one or more elections.
                </li>
                <li>
                    <b>RCV - Study RCV:</b>
                    Any bill commissioning a study of ranked-choice voting.
                </li>
                <li>
                    <b>RCV - Federal elections:</b>
                    Any bill concerning the use of ranked-choice voting in federal-level elections.
                </li>
                <li>
                    <b>RCV - State elections:</b>
                    Any bill concerning the use of ranked-choice voting in state-level elections.
                </li>
                <li>
                    <b>RCV - Local elections:</b>
                    Any bill concerning the use of ranked-choice voting in local-level elections.
                </li>
                <li>
                    <b>RCV - Executive branch elections:</b>
                    Any bill concerning the use of ranked-choice voting in executive branch elections.
                </li>
                <li>
                    <b>RCV - Legislative branch elections:</b>
                    Any bill concerning the use of ranked-choice voting in legislative branch elections.
                </li>
                <li>
                    <b>RCV - Judicial branch elections:</b>
                    Any bill concerning the use of ranked-choice voting in judicial branch elections.
                </li>
                <li>
                    <b>RCV - Primaries:</b>
                    Any bill concerning the use of ranked-choice voting in primaries.
                </li>
                <li>
                    <b>RCV - General elections:</b>
                    Any bill concerning the use of ranked-choice voting in general elections.
                </li>
                <li>
                    <b>RCV - Statutory changes:</b>
                    Any bill concerning the use of ranked-choice voting that would be implemented through state statute.
                </li>
                <li>
                    <b>RCV - Constitutional amendment:</b>
                    Any bill concerning the use of ranked-choice voting that would be implemented through a constitutional
                    amendment.
                </li>
                <li>
                    <b>RCV - Voter approval:</b>
                    Any bill concerning the use of ranked-choice voting that would require voter approval to take effect.
                </li>
                <li>
                    <b>RCV - Top-three primary:</b>
                    Any bill concerning ranked-choice voting in a primary election in which the top three vote-getters
                    advance to the general election.
                </li>
                <li>
                    <b>RCV - Top-four primary:</b>
                    Any bill concerning ranked-choice voting in a primary election in which the top four vote-getters
                    advance to the general election.
                </li>
                <li>
                    <b>RCV - Top-five primary:</b>
                    Any bill concerning ranked-choice voting in a primary election in which the top five vote-getters
                    advance to the general election.
                </li>
                <li>
                    <b>RCV - Single winner:</b>
                    Any bill concerning ranked-choice voting in an election in which the one candidate who receives the
                    greatest number of votes is elected to a single office.
                </li>
                <li>
                    <b>RCV - Multiple winners:</b>
                    Any bill concerning ranked-choice voting in an election in which more than one candidate is elected to a
                    single office.
                </li>
                <li>
                    <b>RCV - Proportional winners:</b>
                    Any bill concerning a ranked-choice voting election that uses a proportional system in which a candidate
                    must reach a predetermined threshold for victory (eg. 25% of first-ranked votes) after which no more
                    ballots will be counted towards their election. Any remaining ballots that rank a candidate first that
                    has already reached the threshold will have their next highest ranked vote for a remaining eligible
                    candidate counted towards that candidate's election. This process repeats until the number of candidates
                    equaling the seats up for election have reached the predetermined threshold and all seats have been
                    filled.
                </li>
                <li>
                    <b>RCV - Absentee voting:</b>
                    Any bill regulating absentee voting in ranked-choice elections.
                </li>
                <li>
                    <b>RCV - Early voting:</b>
                    Any bill concerning the use of ranked-choice voting specifically for early voting.
                </li>
                <li>
                    <b>RCV - In-person voting:</b>
                    Any bill concerning the use of ranked-choice voting specifically for in-person voting.
                </li>
                <li>
                    <b>RCV - Military/overseas voting:</b>
                    Any bill regulating military or overseas voting in ranked-choice elections.
                </li>
            </ol>
            </li>
            <li>
                <b>Audits and oversight</b>
            <ol>
                <li>
                    <b>Audits:</b>
                    Any bill that involves auditing election results and/or election processes, procedures, or election
                    officials’ performance.
                </li>
                <li>
                    <b>Oversight and accountability:</b>
                    Any bill creating or modifying oversight authorities, investigatory or disciplinary review processes for
                    state or local officials, or public reporting requirements.
                </li>
                <li>
                    <b>Poll observers:</b>
                    Any bill affecting poll observer training or qualifications, or affecting observer access to any part of
                    the voting and canvassing process.
                </li>
                <li>
                    <b>States of emergency:</b>
                    Any bill affecting the modification of the times, places, or manner of elections during declared
                    emergencies.
                </li>
                <li>
                    <b>Transparency:</b>
                    Any bill that affects the publication of election data, such as the posting of ballot images online or
                    the publication of voter rolls.
                </li>
            </ol>
            </li>
            <li>
                <b>Ballot access</b>
            <ol>
                <li>
                    <b>Ballot access for ballot measures (initiative and referendum):</b>
                    Any bill that affects the process by which ballot measures qualify for the ballot or changes procedural
                    or documentation requirements. This applies to local initiatives, bond elections, referenda, and
                    constitutional amendments.
                </li>
                <li>
                    <b>Ballot access for candidates:</b>
                    Any bill that affects the process(es) by which individual candidates file for placement on the ballot or
                    changes procedural or documentation requirements.
                </li>
                <li>
                    <b>Ballot access for parties:</b>
                    Any bill that affects the process(es) by which political parties can be granted ballot status or changes
                    procedural or documentation requirements.
                </li>
                <li>
                    <b>Personal qualifications for ballot access</b>: Any bill that addresses personal (non-procedural)
                    qualifications for ballot access, such as age or residency requirements.
                </li>
            </ol>
            </li>
            <li>
                <b>Ballot verification</b>
            <ol>
                <li>
                    <b>Cure provisions:</b>
                    Any bill that creates, eliminates, or amends ballot curing provisions, including signature-matching
                    requirements (see also: “Signature matching”).
                </li>
                <li>
                    <b>In-person voter ID:</b>
                    Any bill that concerns voter identification requirements for in-person voting. This category does not
                    include voter identification requirements for voter registration (see “Voter ID for registration”).
                </li>
                <li>
                    <b>Signature matching:</b>
                    Any bill that concerns a signature matching process for absentee/mail-in ballots.
                </li>
                <li>
                    <b>Voter ID for absentee/mail-in ballots:</b>
                    Any bill that concerns voter identification requirements for absentee/mail-in voting, including
                    requiring identification when requesting an absentee/mail-in ballot. This category does not include
                    voter identification requirements for voter registration (see “Voter ID for registration”).
                </li>
                <li>
                    <b>Voter ID for low-income voters:</b>
                    Any bill that concerns a program to provide free or low-cost identification to low-income voters.
                </li>
                <li>
                    <b>Voter ID for registration:</b>
                    Any bill that concerns identification requirements for registering to vote by mail.
                </li>
                <li>
                    <b>Witness or notary requirements:</b>
                    Any bill that requires absentee/mail-in voters to have their ballots witnessed or notarized to affirm
                    the voter’s identity.
                </li>
            </ol>
            </li>
            <li>
                <b>Ballots and voting materials</b>
            <ol>
                <li>
                    <b>Ballot design:</b>
                    Any bill concerning the design, form, or content of ballots, including the placement of candidates’
                    names on the ballot, straight-ticket voting, and official ballot measure summaries.
                </li>
                <li>
                    <b>Language accommodations:</b>
                    Any bill concerning the provision of language accommodation materials.
                </li>
                <li>
                    <b>Voting materials:</b>
                    Any bill concerning the form or content of official voting materials—excluding ballots—such as voter
                    guides.
                </li>
            </ol>
            </li>
            <li>
                <b>Bills without substantive content</b>
            <ol>
                <li>
                    <b>Bills without substantive content:
                    </b>Any bill that does not contain substantive content. Such bills may be filed as placeholders at the
                    beginning of a legislative session. If such bills are later amended with substantive provisions, they
                    will be put in new categories.
                </li>
            </ol>
            </li>
            <li>
                <b>Counting and certification</b>
            <ol>
                <li>
                    <b>Certification:</b>
                    Any bill affecting
                    how election results are certified.
                </li>
                <li>
                    <b>Counting and canvassing procedures:</b>
                    Any bill concerning how ballots are processed, counted, and/or canvassed.
                </li>
                <li>
                    <b>National Popular Vote Interstate Compact:</b>
                    Any bill intended to join or withdraw from the National Popular Vote Interstate Compact.
                </li>
                <li>
                    <b>Recounts:</b>
                    Any bill affecting
                    how recounts and recanvasses are conducted.
                </li>
                <li>
                    <b>Reporting:</b>
                    Any bill affecting how election returns are posted, including measures establishing online dashboards or
                    requiring that tallies be reported or updated at certain intervals or deadlines.
                </li>
            </ol>
            </li>
            <li>
                <b>Early voting (in-person)</b>
            <ol>
                <li>
                    <b>Early voting administration:</b>
                    Any bill concerning early voting administration.
                </li>
                <li>
                    <b>Early voting duration:</b>
                    Any bill that sets or alters the duration of the early voting period, including early voting hours.
                </li>
                <li>
                    <b>Early voting eligibility:</b>
                    Any bill that either expands or restricts early voting eligibility, including any excuse requirement to
                    vote early.
                </li>
            </ol>
            </li>
            <li>
                <b>Election dates and deadlines</b>
            <ol>
                <li>
                    <b>Administrative deadlines:</b>
                    Any bill that changes an administrative deadline.
                </li>
                <li>
                    <b>Alignment of elections:</b>
                    Any bill that moves or allows jurisdictions to move elections to coincide with existing state and/or
                    federal election dates, such as moving an election from odd- to even-year cycles or from spring dates to
                    November.
                </li>
                <li>
                    <b>Ballot access deadlines:</b>
                    Any bill that changes a candidate or ballot measure access deadline.
                </li>
                <li>
                    <b>Election dates:</b>
                    Any bill that changes an election date.
                </li>
                <li>
                    <b>Voter participation deadlines:</b>
                    Any bill that changes a voter participation deadline (e.g., registration, absentee/mail-in ballot
                    request, etc.).
                </li>
            </ol>
            </li>
            <li>
                <b>Election Day voting</b>
            <ol>
                <li>
                    <b>Electioneering:</b>
                    Any bill that addresses attempts to influence voters, such as providing food or things of value to
                    voters at polling places.
                </li>
                <li>
                    <b>In-person voting and polling places:</b>
                    Any bill that concerns the administration of in-person voting on Election Day.
                </li>
                <li>
                    <b>Provisional ballots:</b>
                    Any bill regarding provisional voting.
                </li>
            </ol>
            </li>
            <li>
                <b>Election funding</b>
            <ol>
                <li>
                    <b>Federal funding:</b>
                    Any bill that regulates how election officials may use grant funding provided by the federal government.
                </li>
                <li>
                    <b>Private funding:</b>
                    Any bill that deals directly with whether private funding can be used for election administration.
                </li>
                <li>
                    <b>Public campaign financing:
                    </b>Any bill that enacts, modifies, or repeals public financing of campaigns.
                </li>
                <li>
                    <b>Redistricting funding:</b>
                    Any bill that provides funding for redistricting.
                </li>
                <li>
                    <b>State appropriations:</b>
                    Any bill that appropriates tax dollars for election administration.
                </li>
            </ol>
            </li>
            <li>
                <b>Election officials and workers</b>
            <ol>
                <li>
                    <b>Election officials:</b>
                    Any bill that sets requirements or regulations for state or local election officials.
                </li>
                <li>
                    <b>Election workers/volunteers:</b>
                    Any bill that sets regulations, qualifications, or training requirements for election workers or
                    volunteers.
                </li>
                <li>
                    <b>Protections for election officials or workers:
                    </b>Any bill that concerns protections for election officials, workers, or volunteers, including bills
                    that create penalties for intimidation, harassment, or other prohibited behavior.
                </li>
            </ol>
            </li>
            <li>
                <b>Election types and contest-specific procedures</b>
            <ol>
                <li>
                    <b>Ballot measures and initiatives:</b>
                    Any bill that deals with ballot measures or initiatives, including requirements for how a measure
                    appears on the ballot or election dates for ballot questions. (See also: Ballot access for ballot
                    measures (initiative and referendum))
                </li>
                <li>
                    <b>Bond elections:
                    </b>Any bill concerning the administration of bond elections.
                </li>
                <li>
                    <b>Elections in specific jurisdictions:
                    </b>Any bill that concerns election administration in specifically named municipalities, jurisdictions,
                    or special-purpose districts, including charter amendments.
                </li>
                <li>
                    <b>Municipal election procedures:</b>
                    Any bill containing provisions exclusively related to the administration of municipal elections.
                </li>
                <li>
                    <b>Nominating caucuses or conventions:</b>
                    Any bill addressing local, state, or federal political party nominating caucuses or conventions.
                </li>
                <li>
                    <b>Party labels:</b>
                    Any bill that changes whether an office is elected on a partisan or nonpartisan basis, or otherwise
                    changes whether or how party labels appear on the ballot, including cross-endorsement or fusion voting.
                </li>
                <li>
                    <b>Presidential elections:</b>
                    Any bill containing provisions exclusively related to presidential elections.
                </li>
                <li>
                    <b>Primary systems:</b>
                    Any bill containing provisions exclusively concerning the administration of primary elections, such as
                    bills related to open or closed primaries or multi-winner primaries.
                </li>
                <li>
                    <b>Recalls:</b>
                    Any bill that concerns recall elections.
                </li>
                <li>
                    <b>Runoffs:
                    </b>Any bill that concerns runoff elections.
                </li>
                <li>
                    <b>School board elections</b>: Any bill containing provisions exclusively related to school board
                    elections.
                </li>
                <li>
                    <b>Special election procedures:</b>
                    Any bill that concerns the administration of special elections.
                </li>
                <li>
                    <b>Vacancy procedures:</b>
                    Any bill that deals with how vacancies are filled.
                </li>
            </ol>
            </li>
            <li>
                <b>Enforcement and election fraud</b>
            <ol>
                <li>
                    <b>Enforcement against non-officials:</b>
                    Any bill concerning civil or criminal penalties associated with election fraud or other non-compliance
                    on the part of a non-official.
                </li>
                <li>
                    <b>Enforcement against officials:</b>
                    Any bill concerning civil or criminal penalties associated with election fraud or other non-compliance
                    on the part of an official.
                </li>
            </ol>
            </li>
            <li>
                <b>Legal conflicts and litigation</b>
            <ol>
                <li><b>Conflicts between levels of government:</b> Any bill that addresses conflicting election laws and procedures
                    between one level of government and another.</li>
                <li>
                    <b>Legal causes of action:</b>
                    Any bill that regulates the filing of election-related or redistricting litigation.
                </li>
                <li>
                    <b>Legislative authority:</b>
                    Any bill that establishes a legislative right to intervene in election litigation, or to approve of
                    settlements or consent decrees in election-related cases.
                </li>
                <li>
                    <b>Partisan gerrymandering:</b>
                    Any bill that creates, alters, or eliminates a cause of action for partisan gerrymandering.
                </li>
                <li>
                    <b>Post-election challenges:</b>
                    Any bill affecting the process for bringing or resolving post-election challenges.
                </li>
            </ol>
            </li>
            <li>
                <b>Offices</b>
            <ol>
                <li><b>Elected official term length:</b> Any bill that changes the term length of an elected public official,
                    changing the frequency of elections for that office.</li>
                <li><b>Offices up for election:</b> Any bill that creates or eliminates an elected public office or alters the
                    number of seats in an elected governing body, changing the types or number of offices up for election.
                    This includes bills that change a previously elected office to an appointed office, and vice-versa.</li>
            </ol>
            </li>
            <li>
                <b>Redistricting</b>
            <ol>
                <li>
                    <b>Independent redistricting commission:
                    </b>Any bill that establishes, eliminates, or modifies an independent redistricting commission.
                </li>
                <li>
                    <b>Redistricting:</b>
                    Any bill that creates or alters district boundaries.
                </li>
                <li>
                    <b>Redistricting commissions:</b>
                    Any bill that creates, alters, or establishes a commission for reapportionment.
                </li>
                <li>
                    <b>Redistricting criteria/requirements:</b>
                    Any bill that creates or alters criteria for redistricting, such as contiguity, compactness, communities
                    of interest, etc.
                </li>
                <li>
                    <b>Redistricting process:</b>
                    Any bill that alters the process by which district boundaries are drawn.
                </li>
            </ol>
            </li>
            <li>
                <b>Voter assistance</b>
            <ol>
                <li>
                    <b>Absentee/mail-in voter assistance:</b>
                    Any bill that regulates the types of assistance available to individuals voting by mail.
                </li>
                <li>
                    <b>In-person voter assistance:</b>
                    Any bill that regulates the types of assistance available to individuals voting in person.
                </li>
            </ol>
            </li>
            <li>
                <b>Voters and voter qualifications</b>
            <ol>
                <li>
                    <b>Mandatory voting:</b>
                    Any bill that establishes or modifies a mandatory/universal voting regime.
                </li>
                <li>
                    <b>Military/UOCAVA voting:</b>
                    Any bill addressing issues specifically arising from military or overseas voting.
                </li>
                <li>
                    <b>Noncitizen voting:</b>
                    Any bill addressing whether noncitizens may vote in elections within a state.
                </li>
                <li>
                    <b>Time off for voting:</b>
                    Any bill that addresses time off work for voting, or creates or eliminates a public holiday.
                </li>
                <li>
                    <b>Voting by felons or incarcerated individuals:</b>
                    Any bill that concerns voting by people convicted of a felony or incarcerated individuals.
                </li>
                <li>
                    <b>Youth voting:</b>
                    Any bill that lowers the voting age, or that permits minors to vote in primary or municipal elections so
                    long as they are 18 by the next general election.
                </li>
            </ol>
            </li>
            <li>
                <b>Voter registration and list maintenance</b>
                <ol>
                    <li>
                        <b>Automatic voter registration:</b>
                        Any bill addressing the automatic registration of voters.
                    </li>
                    <li>
                        <b>Availability of state voter files:</b>
                        Any bill addressing the availability or cost of state voter files.
                    </li>
                    <li>
                        <b>Same-day/Election Day registration:</b>
                        Any bill addressing the ability of voters to register and vote on the same day.
                    </li>
                    <li>
                        <b>Voter list maintenance:</b>
                        Any bill that establishes or modifies processes for maintaining voter roll accuracy, addresses
                        membership in an interstate program such as the Electronic Registration Information Center (ERIC), etc.
                    </li>
                    <li>
                        <b>Voter registration:</b>
                        Any bill that deals with the voter registration process, including registration deadlines.
                    </li>
                </ol>
            </li>
        <li>
            <b>Voting equipment</b>
            <ol>
                <li>
                    <b>Digital/electronic voting:</b>
                    Any bill that regulates the use of digital/electronic voting.
                </li>
                <li>
                    <b>Testing and certification:</b>
                    Any bill that addresses pre- and post-election testing and certification of voting equipment.
                </li>
                <li>
                    <b>Voting equipment:</b>
                    Any bill that deals directly with voting equipment.
                </li>
            </ol>
        </li>
        <li>
            <b>Voting security</b>
        <ol>
            <li>
                <b>Ballot collection:</b>
                Any bill that regulates, limits, or bans the collection of ballots by third parties.
            </li>
            <li>
                <b>Chain of custody:</b>
                Any bill that establishes or alters requirements for maintaining chain of custody for ballots.
            </li>
            <li>
                <b>Cybersecurity:</b>
                Any bill that establishes or alters requirements for cybersecurity of electronic election-related
                systems.
            </li>
            <li>
                <b>Drop-box security:</b>
                Any bill that sets or alters requirements for the physical security of drop boxes, such as requiring
                security cameras or in-person staffing.
            </li>
            <li>
                <b>Physical security:</b>
                Any bill affecting physical security requirements for facilities, equipment, or ballots, such as
                requiring escort of ballots during transport.
            </li>
            <li>
                <b>Video surveillance:</b>
                Any bill establishing video surveillance requirements for facilities, equipment, or ballots, including
                the installation of security cameras or the streaming of live feeds.
            </li>
        </ol>
        </li>
    </ol>
    </div>
    <div v-if="this.$topicPath === 'admin-state'">
    <ol>
        <li>
            <b>Regulation</b>
            <ol>
                <li>
                    <b>Regulatory reduction:</b>
                    These bills propose a mechanism aimed at reducing regulations, such as a regulatory reduction task force.
                </li>
                <li>
                    <b>Regulatory budgets:</b>
                    These bills propose a regulatory budget framework with the intent of limiting or reducing regulations, such as a requirement that two rules must be rescinded for each new rule issued.
                </li>
                <li>
                    <b>Permitting procedures:</b>
                    These bills propose general permitting procedures (known as general permits or permits by rule) or reduce permitting regulations.
                </li>
                <li>
                    <b>Guidance documents:</b>
                    These bills concern guidance documents issued by state administrative agencies, most often regarding their enforcement.
                </li>
            </ol>
        </li>
        <li>
            <b>Adjudication and enforcement</b>
            <ol>
                <li>
                    <b><a href="https://ballotpedia.org/Reform_proposals_related_to_due_process_and_procedural_rights_in_the_context_of_the_administrative_state" target="_blank">Administrative judges</a>:</b>
                    These bills make changes to a state’s administrative judges, most often concerning the creation or abolition of central panels of administrative judges to hear all agency cases.
                </li>
            </ol>
        </li>
        <li>
            <b>Delegation</b>
            <ol>
                <li>
                    <b>Delegation:</b>
                    These bills concern the legislative delegation of authority to agencies to promulgate rules, or subsequent agency delegations of authority to sub-entities.
                </li>
            </ol>
        </li>
        <li>
            <b>Regulatory review</b>
            <ol>
                <li>
                    <b><a href="https://ballotpedia.org/Regulatory_review" target="_blank">Legislative review of agency actions</a>:</b>
                    These bills propose legislative approval or review of agency regulations by a state legislative review committee, often before the rules can take effect. These bills may also create legislative committees tasked with reviewing other agency actions.
                </li>
                <li>
                    <b><a href="https://ballotpedia.org/REINS_Act" target="_blank">REINS Act</a>:</b>
                    These bills require state legislative approval of rules with associated costs above a certain monetary threshold.
                </li>
                <li>
                    <b><a href="https://ballotpedia.org/Sunset_provision" target="_blank">Sunset review</a>:</b>
                    These bills create or modify sunset review procedures. Sunset procedures may require a regular schedule for legislative review of agency rules or they may cause a rule to automatically expire at a certain date unless approved by the state legislature to remain in effect.
                </li>
                <li>
                    <b>Other regulatory oversight:</b>
                    These bills establish other compliance authorities outside of the legislature (either within agencies themselves or as independent entities in the executive branch) to monitor agency rulemaking. 
                </li>
            </ol>
        </li>
        <li>
            <b>Agency structure and authority</b>
            <ol>
                <li>
                    <b>Agency structure and authority:</b>
                    These bills limit or grow agency authority in a substantial way, often through a funding reduction or decrease of regular duties
                </li>
            </ol>
        </li>
        <li>
            <b>Judicial review</b>
            <ol>
                <li>
                    <b>Judicial review of agency actions:</b>
                    These bills establish or modify a process for judicial review of agency actions.
                </li>
                <li>
                    <b><a href="https://ballotpedia.org/Reform_proposals_related_to_judicial_deference" target="_blank">Judicial deference</a>:</b>
                    These bills most often propose de novo review of agency rules, but may place other limits on judicial deference procedures in the state.
                </li>
            </ol>
        </li>
        <li>
            <b>Agency procedure</b>
            <ol>
                <li>
                    <b><a href="https://ballotpedia.org/Rulemaking" target="_blank">Rulemaking process</a>:</b>
                    These bills propose changes to the state rulemaking process.
                </li>
                <li>
                    <b><a href="https://ballotpedia.org/Reform_proposals_related_to_the_nondelegation_doctrine#Apply%20a%20strict%20nondelegation%20doctrine%20to%20agency%20cost-benefit%20analyses" target="_blank">Cost-benefit analysis</a>:</b>
                    These bills establish or modify requirements for agency cost-benefit analysis, sometimes referred to as an economic impact analysis.
                </li>
                <li>
                    <b><a href="https://ballotpedia.org/Reform_proposals_related_to_agency_dynamics" target="_blank">Public participation</a>:</b>
                    These bills advance public participation in administrative processes by requiring or modifying procedures for open meetings or <a href="https://ballotpedia.org/Comment_period" target="_blank">comment periods</a>.
                </li>
            </ol>
        </li>
        <li>
            <b>Federalism</b>
            <ol>
                <li>
                    <b><a href="https://ballotpedia.org/Federalism" target="_blank">Federalism</a>:</b>
                    These bills affect the federalism dynamic between the federal government, state governments, and/or local governments.
                </li>
                <li>
                    <b><a href="https://ballotpedia.org/Unfunded_mandates" target="_blank">Unfunded mandates</a>:</b>
                    These bills place limits/requirements on the acceptance of federal funds by states, or mandates by states on local governments.
                </li>
                <li>
                    <b>Federalism committees:</b>
                    These bills establish or affect the duties and powers and duties of federalism committees in state legislatures.
                </li>
            </ol>
        </li>
        <li>
            <b>Executive authority</b>
            <ol>
                <li>
                    <b><a href="https://ballotpedia.org/Reform_proposals_related_to_executive_appointment_and_removal_power" target="_blank">Appointment and removal</a>:</b>
                    These bills modify the governor’s authority to appoint or remove state executive officials, most often concerning requirements for senate confirmation. These bills may also call for the election of previously appointed officials, or visa versa.
                </li>
                <li>
                    <b>Legislative review of executive actions:</b>
                    These bills propose a mechanism for the state legislature to review or rescind executive orders issued by the governor.
                </li>
            </ol>
        </li>
        <li>
            <b>Occupational licensing</b>
            <ol>
                <li>
                    <b>Occupational licensing:</b>
                    These bills recognize licensing reciprocity between states or eliminate licensing for an occupation. They may also decrease licensing requirements or associated fees.
                </li>
            </ol>
        </li>
    </ol>
    </div>
    <div v-if="this.$topicPath === 'ai-deepfakes'">
        <ol>
            <li>
                <b>Political communications:</b>
                Any bill concerning the use of deepfakes in political communications, such as campaign advertisements.
                <ol>
                    <li>
                        <b>Political deepfake ban:</b> 
                        Any bill prohibiting the distribution of political deepfakes.
                    </li>
                    <li>
                        <b>Pre-election political deepfake ban:</b> 
                        Any bill prohibiting the distribution of political deepfakes during a certain time period before an election.
                    </li>
                    <li>
                        <b>Political deepfake ban with disclosure exception:</b> 
                        Any bill requiring political deepfakes to include a disclosure in order to be distributed legally.
                    </li>
                </ol>
            </li>
            <li>
                <b>Pornographic material:</b>
                Any bill concerning the creation or distribution of deepfakes that depict explicit sexual acts or other sensitive content.
                <ol>
                    <li>
                        <b>Child sexual abuse material:</b> 
                        Any bill concerning the creation or distribution of deepfakes that depict child sexual abuse or other sensitive content involving minors.
                    </li>
                </ol>
            </li>
            <li>
                <b>Consumer protection, fraud, or extortion:</b>
                Any bill concerning consumer protections related to deepfakes or the use of deepfakes to commit fraud or extortion.
            </li>
            <li>
                <b>Property, labor, or creative rights:</b>
                Any bill concerning the infringement of deepfakes upon property rights, labor rights, or creative rights.
            </li>
            <li>
                <b>Defamation:</b>
                Any bill concerning deepfakes used in a defamatory manner. 
            </li>
            <li>
                <b>Tech entity regulation:</b>
                Any bill establishing or modifying regulations on companies or individuals that provide software for creating deepfakes, or on online platforms where deepfakes are shared.
                <ol>
                    <li>
                        <b>Watermark requirement:</b> 
                        Any bill establishing or modifying a requirement for deepfake materials to include an embedded watermark or other digital provenance feature.
                    </li>
                </ol>
            </li>
            <li>
                <b>Evidence authenticity:</b>
                Any bill concerning the use of deepfake technology to create inauthentic evidence in the realm of law enforcement.
            </li>
            <li>
                <b>Public education programs or requirements:</b>
                Any bill creating or modifying programs or requirements for public education systems, schools, or teachers related to deepfakes.
            </li>
            <li>
                <b>Resolution:</b>
                A legislative resolution related to deepfakes.
            </li>
            <li>
                <b>Study:</b>
                Any bill that commissions a study or report, or establishes a task force or working group, related to deepfakes.
            </li>
            <li>
                <b>Private cause of action:</b>
                Any bill establishing or modifying a private cause of action related to the creation or distribution of deepfakes.
            </li>
            <li>
                <b>Civil penalties:</b>
                Any bill establishing or modifying civil penalties related to the creation or distribution of deepfakes.
            </li>
            <li>
                <b>Criminal penalties:</b>
                Any bill establishing or modifying criminal penalties related to the creation or distribution of deepfakes. 
            </li>
            <li>
                <b>Communication service provider immunity:</b>
                Any bill stipulating that communication service providers are not liable for illegal deepfakes distributed on their platforms.
            </li>
            <li>
                <b>Satire or parody exception:</b>
                Any bill making an explicit exception for satire or parody uses of deepfakes.
            </li>
        </ol>
    </div>

<div v-if="this.$topicPath != 'ai-deepfakes'">
    <p class="bold">How are category tags applied?</p>
    <p>After we add a bill to our database, we review the bill text to identify the {{this.$topicNameForTextContent}} policy areas the bill would
        affect. Once the broad policy area(s) are identified, we then add category tags for each subject in the bill.
        <span v-if="this.$topicPath === 'elections'">For example, a bill addressing ballot access would receive a category tag based on whether it would affect
        ballot access for candidates, political parties, or ballot measures.</span></p>
    </div>
    <p v-if="this.$topicPath === 'elections'">A note on <em>ranked-choice voting</em> (RCV) tags: 
        We use a more detailed tagging system for ranked-choice voting bills. We determine what the bill's objective is,
        then determine what level and branch of government it would affect, what type of elections are involved, and
        whether voter approval is required. Additional tags clarify whether the bill's provisions apply to certain
        primary types or voting methods and systems.
    </p>

    <p class="bold">What are some tips for using the search function?</p>
    <p>The search function is very flexible, enabling users to filter bills by a variety of criteria:</p>
    <ul>
        <li>You can select topics from the drop-down "Topic" menu, selecting as many topics as desired. Alternatively,
            you can enter a phrase into the "Keyword search" field to pull up all the bills containing that phrase in
            the official bill title or caption.</li>
        <li>You can select partisan sponsorship criteria from the drop-down "Sponsor Party" menu. You can also search by
            state trifecta status (e.g., bills introduced in states with Democratic or Republican trifectas).</li>
        <li>You can select status criteria from the drop-down "Current bill status" menu, selecting as many status
            indicators as desired (e.g., you can search for all bills that have either been enacted or passed both
            chambers of a state legislature).</li>
        <li>You can select a state (or multiple states) from the drop-down "State" menu.</li>
    </ul>
    <p>You can employ as many of these fields in combination as desired. For example, you could conduct a single search
        for all bills that meet the following criteria:</p>
    <ul>
        <li v-if="this.$topicPath === 'elections'">The bill deals with absentee/mail-in voting.</li>
        <li v-if="this.$topicPath === 'ai-deepfakes'">The bill deals with political communications.</li>
        <li>The bill was sponsored by Republicans.</li>
        <li>The bill is in a Republican trifecta state.</li>
        <li>The bill has passed both chambers or been enacted.</li>
    </ul>

    <span v-if="this.$topicPath === 'elections'">
    <p class="bold">Why doesn’t Ballotpedia label bills based on their perceived effects?</p>
    <p>Some media outlets and advocacy groups use terms such as <em>increasing voter access</em>, <em>restricting voter access</em>, <em>protecting election integrity</em>, or <em>endangering election integrity</em>.
    </p>
    <p>We tag and summarize bills based on the specific changes they would make to existing law. Consider the following
        examples:</p>
    <ul>
        <li>Example: A bill establishing a photo identification requirement for voters.</li>
        <ul>
            <li>Democrats/liberals might say such a bill restricts voter access, arguing that it imposes an unnecessary
                burden on eligible voters.</li>
            <li>Republicans/conservatives might say such a bill secures election integrity by using widely available
                forms of identification to ensure that only eligible voters are able to cast ballots.</li>
        </ul>
        <li>Example: A bill allowing third parties to return voters' completed absentee/mail-in ballots.</li>
        <ul>
            <li>Democrats/liberals may say such a bill improves voter access, making voting easier for those who might
                otherwise have difficulty returning their completed ballots.</li>
            <li>Republicans/conservatives might say such a bill threatens election integrity, arguing that such a
                provision amounts to what they sometimes call "ballot trafficking" and increases the likelihood that
                third parties will exercise untoward influence on voters.</li>
        </ul>
    </ul>
    <p>Ballotpedia does not pass judgment on a bill's effects. Instead, we want our readers to decide how each bill will
        affect the electoral system as a whole.</p>
    </span>

    <div v-if="this.$topicPath === 'ai-deepfakes'" class="footnotes">
      <!-- footnotes -->
     <hr>
      <ol>
        <li><i><a href="https://www.gao.gov/assets/gao-20-379sp.pdf" target="_blank">U.S. Government Accountability Office, “Science & Tech Spotlight: Deepfakes,” February 2020</a></i></li>
        <li><i><a href="https://www.nytimes.com/2024/04/08/technology/deepfake-ai-nudes-westfield-high-school.html" target="_blank">The New York Times, “Teen Girls Confront an Epidemic of Deepfake Nudes in Schools,” April 8, 2024
</a></i></li>
        <li><i><a href="https://apnews.com/article/fec-artificial-intelligence-deepfakes-election-2024-95399e640bd1e41182f6c631717cc826" target="_blank">AP News, “FEC moves toward potentially regulating AI deepfakes in campaign ads,” August 10, 2023</a></i></li>
        <li><i><a href="https://www.brennancenter.org/our-work/research-reports/regulating-ai-deepfakes-and-synthetic-media-political-arena" target="_blank">Brennan Center for Justice, “Regulating AI Deepfakes and Synthetic Media in the Political Arena,” December 5, 2023</a></i></li>
        <li><i><a href="https://www.nbcnews.com/politics/states-turn-attention-regulating-ai-deepfakes-2024-rcna135122" target="_blank">NBC News, “States turn their attention to regulating AI and deepfakes as 2024 kicks off,” January 25, 2024</a></i></li>
        <li><i><a href="https://apnews.com/article/deepfake-images-taylor-swift-state-legislation-bffbc274dd178ab054426ee7d691df7e" target="_blank">AP News, “What to know about how lawmakers are addressing deepfakes like the ones that victimized Taylor Swift,” January 31, 2024</a></i></li>
        <li><i><a href="https://www.multistate.us/insider/2024/4/5/more-and-more-states-are-enacting-laws-addressing-ai-deepfakes" target="_blank">MultiState, “More and More States Are Enacting Laws Addressing AI Deepfakes,” April 5, 2024</a></i></li>
        <li><i><a href="https://www.regulations.gov/comment/NIST-2023-0009-0030" target="_blank">Regulations.gov, “Comment on FR Doc # 2023-28232,” January 31, 2024</a></i></li>
        <li><i><a href="https://www.acluga.org/en/press-releases/press-statement-aclu-georgia-opposes-bill-criminalizing-deep-fakes-about-election" target="_blank">ACLU of Georgia, “Press Statement: ACLU of Georgia Opposes Bill Criminalizing 'Deep Fakes' about Election Candidates,” January 29, 2024</a></i></li>
        <li><i><a href="https://www.courthousenews.com/free-speech-implications-surface-as-experts-urge-senate-to-regulate-deepfakes/" target="_blank">Courthouse News Service, “Free speech implications surface as experts urge Senate to regulate deepfakes,” April 30, 2024</a></i></li>
        <li><i><a href="https://www.thefire.org/research-learn/artificial-intelligence-free-speech-and-first-amendment" target="_blank">Foundation for Individual Rights and Expression, “Artificial intelligence, free speech, and the First Amendment,” accessed May 30, 2024</a></i></li>
      </ol>
      </div>

</div>
</template>


<script>
export default {
    name: 'FAQ',
    props: {

    }
}



</script>


<style scoped>.add-spacing li {
    margin-bottom: 10px;
}


.footnotes{
  font-size: .8em;
}

.intro-p {
  margin-bottom: 10px;
}

p {
    margin-top: 10px;
}

.bold {
    font-weight: 600;
    margin-top: 60px;
    text-decoration: underline;
}

ul {
    margin-top: 10px;
}

.faq-container {
    color: #444444;
    margin-left: 15%;
    margin-right: 15%;
    margin-top: 130px;
}

@media screen and (max-width: 1000px) {
    .faq-container {
        color: #444444;
        margin-left: 30px;
        margin-right: 30px;
    }

}

@media screen and (max-width: 768px) {
    .faq-container {
        color: #444444;
        margin-left: 15px;
        margin-right: 15px;
        margin-top: 100px;
    }

}

.home-tagline {
    margin-bottom: 60px;
}

h1 {
    text-align: center;
}

ol {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0;
}

ol>li {
    display: table;
    counter-increment: item;
    margin-bottom: 0.6em;
}

ol>li:before {
    content: counters(item, ".") ". ";
    display: table-cell;
    padding-right: 0.6em;
}

li ol>li {
    margin: 0;
}

li ol>li:before {
    content: counters(item, ".") " ";
}</style>
