<template>
  <div id="app">
    <header v-show="!this.$route.query.embed" class="print-hide" v-bind:class="{ 'custom-header': this.$isBallotpediaTracker == false }" >
      
      
      <div id="brand">
        <div v-show="this.$isBallotpediaTracker">
          <!--<router-link :to="this.$route.path.includes('bill') ? '../home' : 'home'" exact>Ballotpedia <span class="brand-small">Legislation Tracker</span></router-link> -->
          <!-- Uncomment below and remove above when adding Admin State -->
          <router-link :to="this.$route.path.includes('bill') ? '../home' : 'home'" exact>Ballotpedia <span class="brand-small">{{this.$topicNameFull}} Legislation Tracker</span></router-link>
        </div>
      </div>


      <nav>
        <ul>
          <!-- BP Tracker Only-->
          <li v-if="this.$isBallotpediaTracker">
            <span class="glyphicon glyphicon-home" aria-hidden = "true"></span>
            <a href="https://ballotpedia.org/Legislation_Trackers" target="_blank"> Legislation Trackers</a>
          </li>
          <li>
            <span class="glyphicon glyphicon-th-large" aria-hidden="true"></span>
            <router-link :to="this.$route.path.includes('bill') ? '../overview' : 'overview'"> Overview data</router-link>
          </li>
          <li>
            <span class="glyphicon glyphicon-search" aria-hidden="true"></span>
            <router-link :to="this.$route.path.includes('bill') ? '../search' : 'search'"> Bill search</router-link>
          </li>
          <li v-if="this.$isBallotpediaTracker && this.$whatCountsListID !== null" class="hidden-lg">
            <span class="glyphicon glyphicon-envelope" aria-hidden="true"></span>
            <a v-if="this.$whatCountsListID !== null" :href="`${this.$newsletterLink}`" target="_blank"> Subscribe</a>
          </li>
        </ul>
      </nav>

      <div v-show="this.$isBallotpediaTracker && this.$whatCountsListID !== null">
        <form action="https://whatcounts.com/bin/listctrl" method="POST" class="form-inline navbar-signup hidden-md visible-lg">
          <input type="hidden" name="slid" :value="this.$whatCountsListID" />
          <input type="hidden" name="cmd" value="subscribe" />
          <input type="hidden" name="wxuiversionfirst" value="" />
          <input type="hidden" name="goto" :value="`https://legislation.ballotpedia.org/${this.$topicPath}/thank-you`"  />
          <input type="hidden" name="errors_to" value="" />
          <input type="email" id="email" name="email" required maxlength="65" placeholder="Subscribe to our digest"
              oninvalid="this.setCustomValidity('Please enter a valid email address')"
              oninput="this.setCustomValidity('')"
              style="width: 250px; margin-right: 10px;"
              />
          <input type="hidden" id="format_mime" name="format" value="mime" />
          <input type="submit" class="btn btn-secondary" value="Subscribe" style="width: 100px; border: 1px solid white; background-color:#F0A142" />
        </form>
      </div>
      <div id="hamburger-icon" :class="this.open ? 'open' : ''" v-on:click="toggleMobileMenu()">
        <div class="bar1"></div>
        <div class="bar2"></div>
        <div class="bar3"></div>
        <ul class="mobile-menu">
          <li><router-link to="home" exact>Home</router-link></li>
          <li><router-link to="overview"> Overview data</router-link></li>
          <li><router-link to="search"> Bill search</router-link></li>
          <li v-show="this.$isBallotpediaTracker"><a href="https://ballotpedia.org/Legislation_Trackers" target="_blank">Legislation Trackers</a></li>
          <li v-show="this.$isBallotpediaTracker"><router-link to="faq"> FAQ</router-link></li>
          <li v-show="this.$isBallotpediaTracker && this.$whatCountsListID !== null"><a :href="`${this.$newsletterLink}`" target="_blank"> Subscribe</a></li>
        </ul>
      </div>
    </header>


    <div class="content-container">
      <!-- The router outlet -- This is where each component will be rendered in the layout: -->
      <router-view :key="$route.fullPath" />
    </div>


    <!-- Footer for Ballotpedia Trackers -->
    <div v-show="this.$isBallotpediaTracker">
      <footer class="print-hide" v-show="!this.$route.query.embed" >
        <div class="footer-logo-container" v-show="!this.$route.query.embed">
          <img src="../src/assets/images/logo.svg" class="footer-logo">
          <p class="footer-line">Return to <span class="bold"><a target="_blank" href="https://ballotpedia.org">Ballotpedia</a></span></p>
        </div>
        <div v-show="!this.$route.query.embed">
          <form v-if="this.$whatCountsListID !== null" action="https://whatcounts.com/bin/listctrl" method="POST" style="margin-bottom: 10px;" class="subscribe-form">
            <input type="hidden" name="slid" :value="this.$whatCountsListID" />
            <input type="hidden" name="cmd" value="subscribe" />
            <input type="hidden" name="wxuiversionfirst" value="" />
            <input type="hidden" name="goto" :value="`https://legislation.ballotpedia.org/${this.$topicPath}/thank-you`" />
            <input type="hidden" name="errors_to" value="" />
            
            <input type="email" id="email" name="email" required maxlength="65" placeholder="Subscribe to our digest"
                oninvalid="this.setCustomValidity('Please enter a valid email address')"
                oninput="this.setCustomValidity('')"
                style="width: 250px; margin-right: 10px;"
                />
            <input type="hidden" id="format_mime" name="format" value="mime" />
            <input type="submit" class="btn btn-primary" value="Subscribe" style="width: 100px" />
          </form>
          <p style="clear: both" class="footer-tagline">Ballotpedia is the digital encyclopedia of American politics and elections. Our goal is to inform people about politics by providing accurate and objective information about politics at all levels of government.</p>
        </div>
        <p v-show="!this.$route.query.embed" class="footer-links"><a target="_blank" href="https://ballotpedia.org/Support">Donate</a> · <a target="_blank" href="https://ballotpedia.org/Report_an_error">Report an error</a> · <a target="_blank" href="https://ballotpedia.org/Ballotpedia_Email_Updates">Email updates</a> · <a target="_blank" v-if="this.$topicPath === 'elections'" href="https://ballotpedia.org/Election_Policy">Election Policy</a> <a target="_blank" v-if="this.$topicPath === 'admin-state'" href="https://ballotpedia.org/Administrative_State_Index">Administrative State Policy</a> · <a target="_blank" href="https://ballotpedia.org/Ballotpedia:Buy_Political_Data">Data sales</a> · <a target="_blank" href="https://ballotpedia.org/Ballotpedia:Buy_Political_Data">API</a> · <a target="_blank" href="https://ballotpedia.org/Ballotpedia:Premium_Research_Services">Premium Research Services</a> · <a target="_blank" href="https://ballotpedia.org/Ballotpedia:What_people_are_saying">Media inquires</a></p>
      </footer>
    </div>

  </div>
</template>

    
    
    



    


<script>
const BALLOT_BULLETIN_DRIFTBOT_ID = 2745052;
function handleEmailCapture(event) {
  fetch(`https://ballotpedia.org/driftbot_subscribe.php?email=${event.data.email}&driftbotid=${BALLOT_BULLETIN_DRIFTBOT_ID}`)
    .then((res) => {
      console.log("SUCCESS!");
      console.log(res);
    })
    .catch((err) => {
      console.log("ERROR!");
      console.log(err);
    })
}
export default {
  name: "App",
  components: {},
  mounted() {
    if (this.$drift) {
      this.$drift.on('emailCapture', handleEmailCapture);
    }
    if(!this.$isBallotpediaTracker)
    {
      const customcss = `
      .custom-header {
        background-color: ${this.$customHeaderColor} !important;
        box-shadow: none !important;
      }
      .custom-header  li a, .custom a, .custom-header  li span  {
        color: ${this.$customPrimary} !important;
      }
      .custom-btn {
        background-color: ${this.$customPrimary} !important;
      }
      #app {
          font-family: ${this.$fontFamily}, Avenir, Helvetica, Arial, sans-serif !important;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }            
        body {
          font-family: ${this.$fontFamily};
          font-size: 18px;
        } 
      `;
      this.styleTag = document.createElement('style');
      this.styleTag.appendChild(document.createTextNode(customcss));
      document.head.appendChild(this.styleTag);
    }
  },
  data() {
    return {
      navigation: true,
      open: false
    };
  },
  created() {

 
    let title = this.$route.path.substring(1);
    title = title.charAt(0).toUpperCase() + title.slice(1);
    title = title.replaceAll('-', ' ');

    document.title = title;

    this.$gtag.pageview(title)


    if (this.$route.query.navigation == "false") {
      this.navigation = false;
    }
  },
  methods: {
    toggleMobileMenu() {
      this.open = !this.open
    }
  }
};



</script>


<style scoped>

@media print {
  .print-hide {
    display: none;
  }
}

.subscribe-form {
  float: right;
}

.subscribe-form input {
  margin-bottom: 2px;
}


.btn {
  padding: unset;
  background: #3047B1;
  color: white;
  border: none;
}
input:placeholder {
  font-style: none !important;
}
footer {
  padding: 40px 30px 10px 30px;
  background: #F7F6F6; 
  background: #f8f8f8;
  margin-top: 100px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: start;
  flex-wrap: wrap;
}

.footer-line {
  padding-left: 12px;
  position: relative;
  bottom: 7px;
  color: #97989C;
}

.footer-links {
  text-align: center;
  width: 100%;
  margin-top: 50px;
  color: #666;
  font-weight: 500;
}

.bold {
  font-weight: 600;
}

.footer-tagline {
  width: 500px;
  color: #666;
  text-align: right;
}

.footer-logo {
  width: 300px;
}
.icon-container {
  width: 35px;
}

@media screen and (max-width: 937px) {
  .footer-tagline {
    width: 100%;
    text-align: center;
    margin-top: 10px;
  }

  .footer-links {
      margin-top: 20px;
  }

  .footer-logo-container {
    display: block;
    margin: 0 auto;
  }
  .subscribe-form {
    float: none;
    text-align: center;
  }


}
@font-face {
  font-family: "Montserrat";
  src: url('../src/assets/fonts/Montserrat.ttf');
}

@font-face {
  font-family: "Merriweather";
  src: url('../src/assets/fonts/Merriweather-Regular.ttf');
}

#app {
  font-family: Montserrat, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: Montserrat;
  font-size: 18px;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
}


.menu-nav-icon {
    padding-right: 5px;
    height: 14px;
}

@media screen and (max-width: 865px) {
  .menu-nav-icon {
    display: none;
  }
}


p {
  margin: 0;
  padding: 0;
}

header {
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    z-index: 9999;
}

/* Header */
header a {
  text-decoration: none;
}

header {
  padding: 10px 30px 10px 30px;
  background-color: #3047B1;
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 0;
  width: 100%;
}

#brand {
  font-weight: 700;
  font-size: 20px;
  display: flex;
  align-items: center;
}

#brand a {
  color: white;
}

.brand-small {
  font-weight: 400;
  /* display: block; */
  font-size: 16px;
  /* position: relative;
  bottom: 5px; */
}

.brand-small-block {
  font-weight: 400;
  display: block;
  font-size: 15px;
  position: relative;
  bottom: 5px;
}



ul {
  list-style: none;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: white;
  font-size: 14px;
  font-weight: 500;
  margin-top: 2px;
  padding-left: 0 !important;
}

ul span {
  margin-right: 3px;
}


@media screen and (max-width: 835px) {
    #brand {
    font-size: 16px;
    font-weight: 600;
  }

    header {
    padding: 10px 20px 10px 20px;
  }  
}

@media screen and (max-width: 768px) {

  
  ul a {
    font-size: 40px;
    font-weight: 600;
    padding-top: 30px;
  }



  
}



@media screen and (max-width: 400px) {
  #brand {
    font-size: 16px;
    font-weight: 600;
  }


}

@media screen and (max-width: 419px) {
  .subscribe-form input {
    margin-top: 10px;
  }
}


ul a:hover, ul a:visited  {
  color: white;
}

ul li {
  padding: 5px;
  margin-left: 30px;
}

ul li:hover {
  transform: scale(1.1);
  transition: 0.25s;
}

.navbar-signup {
  margin-top: 8px;
}

.mobile-menu ul {
  padding-left: 0;
}

.mobile-menu li {
  margin-left: 0;
  padding-top: 20px;
}

#hamburger-icon {
  margin: auto 0;
  display: none;
  cursor: pointer;
}

#hamburger-icon div {
  width: 35px;
  height: 3px;
  background-color: white;
  margin: 6px 0;
  transition: 0.4s;
}

/* .open .bar1 {
  -webkit-transform: rotate(-45deg) translate(-6px, 6px);
  transform: rotate(-45deg) translate(-6px, 6px);
}

.open .bar2 {
  opacity: 0;
}

.open .bar3 {
  -webkit-transform: rotate(45deg) translate(-6px, -8px);
  transform: rotate(45deg) translate(-6px, -8px);
} */

.open .mobile-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-left: 0px;
}

.mobile-menu {
  display: none;
  position: absolute;
  top: 50px;
  left: 0;
  height: calc(100vh - 50px);
  width: 100%;
  background: black;
}

.mobile-menu li {
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  header nav {
    display: none;
  }

  #hamburger-icon {
    display: block;
  }
}



.navigation-container {
  overflow-y: auto;
  width: 225px;
}

.logo-container {
  text-align: center;
  padding: 40px 20px 10px 20px;
}

.logo-container img {
  margin-bottom: 10px;
}

.tagline-big {
  line-height: 20px;
  font-weight: 500;
  font-size: 15px;
}

.tagline-small {
  margin-top: 5px;
  color: #727171;
  font-size: 14px;
}

.hr-nav {
  border: none;
  height: 1px;
  background-color: #e4e7eb;
  margin: 20px 20px 30px 20px;
}

.hr-full {
  border: none;
  height: 1px;
  background-color: #e4e7eb;
}

.link-container {
  padding: 10px 0px 10px 20px;
  display: flex;
  transition: 0.25s;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 5px;
  margin-top: 10px;
  font-weight: 500;
  font-size: 16px;
}

.link-container:hover {
  background: #e2eeff;
  cursor: pointer;
}

.link-container p {
  margin-top: 2px;
}

.link-active,
.router-link-active {
  font-weight: 700;
}

.nav-icon {
  height: 20px;
  display: inline-block;
  display: none;
}

</style>
