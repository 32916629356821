<template>
  <div class="container">
    <img  :src="require(`../../src/assets/images/${this.$trackerLogo}`)" class="header-logo" :alt="this.$topicNameFull + ' legislation tracking'">
    <h3>Thank you for subscribing to our newsletter.</h3>

    <div class="cta-container">
      <router-link to="search"
        ><button class="cta">
          <img src="../../src/assets/images/search-icon-white.svg" /> Bill
          search<span class="small">Click here to search legislation </span>
        </button></router-link
      >
      <router-link to="overview"
        ><button class="cta">
          <img src="../../src/assets/images/overview-icon.svg" /> Overview
          data<span class="small">Click here to see overview </span>
        </button></router-link
      >
    </div>
  </div>
</template>


<script>
export default {
  name: "Thank-You",
  props: {},
};
</script>


<style scoped>
.container {
  text-align: center;
  color: #444444;
  margin-top: 125px;
  height: 400px;
}
.cta {
  margin-top: 40px;
}
</style>
