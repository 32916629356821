<template>


  <div div :class="{ 'bill-container': !this.$route.query.embed }">

    <!-- Meta data social media sharing  -->
    
    <meta v-if="this.$isBallotpediaTracker" property="og:title" content="ballotpedia"/>
    <meta v-if="this.$isBallotpediaTracker" property="og:image" content="https://legislation.ballotpedia.org/img/logo.90aa5190.svg"/>
    <meta v-if="this.$isBallotpediaTracker" property="og:description" content="Ballotpedia's legislation tracker"/>
    <meta v-if="this.$isBallotpediaTracker" property="og:url" :content="`https://legislation.ballotpedia.org${this.$route.path}`" />

    <h1 class="print-only" v-if="this.$isBallotpediaTracker">Ballotpedia Legislation Tracker</h1>

    <div class="header-row print-hide">

      <div v-if="loading" style="width: 100%; text-align: center; padding-bottom: 90px">
        <div class="lds-dual-ring"></div>
      </div>

 


      <div class="share-container" v-show="!this.$route.query.embed && this.$isBallotpediaTracker">
        <div>
          <p>Share this page</p>
          <div class="share-icon-container">
            <a target="_blank" :href="`https://twitter.com/intent/tweet?text=Ballotpedia's legislation tracker for bill https://legislation.ballotpedia.org${this.$route.path} `"><img src="../../src/assets/images/twitter-2.svg" style="height: 26px; width: 26px" /></a>
            <a target="_blank" :href="`https://www.linkedin.com/sharing/share-offsite/?url=https://legislation.ballotpedia.org${this.$route.path}`"><img src="../../src/assets/images/linkedin-2.svg" style="height: 26px; width: 26px;"/> </a>
            <ShareNetwork
              network="facebook"
              :url="`https://legislation.ballotpedia.org${this.$route.path}`"
            >
              <img src="../../src/assets/images/facebook-2.svg" style="height: 30px; width: 30px;"/>
            </ShareNetwork>
            <span @click="print" style="cursor: pointer">🖨️</span>
                    
            <!-- <div class="fb-share-button" :data-href="`https://legislation.ballotpedia.org${this.$route.path}`"  data-layout="button_count"> </div> -->
          </div>
        </div>
      </div>

      <div class="header-container">
        
        <!-- <img src="../../src/assets/images/AL.svg" class="state-img"/>  -->

        <img :src="require(`../../src/assets/images/states/${stateAbbrToName(billData.state)}.png`)" class="state-img"/> 

        <div class="bill-quickview">

          <!-- <p class="state_name">{{ billData.state }}</p> -->
          <p class="state_name">{{ stateAbbrToName(billData.state) }}</p>
          <p class="bill_number">{{ billData.bill_number }}</p>
          <p class="bill_status"><img :src="require(`../../src/assets/images/${
              ['Introduced', 'Crossed over', 'Passed both chambers', 'Enacted', 'Vetoed', 'Veto Overridden', 'Dead'].includes(billData.current_legislative_status) ? billData.current_legislative_status : 'DefaultStatus'
          }.svg`)" class="status-icon"> {{ billData.current_legislative_status }}</p>

        
            <p v-if="categories && categories.length > 0" class="topics-intro">This bill deals with the following topics:</p>
                       
        
            <span v-for="{cat, link}, i in categories" :key="i" class="categories-container">
              <img src="../../src/assets/images/cat-black.svg" class="cat-icon"/>
                    
              <a v-if="isBallotpediaTracker()" target="_blank" v-bind:href="link" >
                <span>{{ cat }}</span>
              </a>

             <span  v-if="!isBallotpediaTracker()" >{{ cat }}</span>
          
           </span>
          
        </div>



      </div>

    </div>

    <hr class="hr-full print-hide">

    <div class="bill-header">
        <p class="bill-header-text">Overview</p>
    </div>

  <div class="subheader-row">
    <div class="full-item-row">
      <p class="item-header">Official Name</p>
      <p>{{ billData.name }}</p>
    </div>


    <div class="item-flex-row">
      <div>
        <p class="item-header">State</p>
        <p>{{ billData.state }}</p>
      </div>

      <div>
        <p class="item-header">Session year</p>
        <p>{{ billData.legislative_sessions }}</p>
      </div>

      <div>
        <p class="item-header">Bill number</p>
        <p>{{ billData.bill_number }}</p>
      </div>

      <div>
        <p class="item-header">Status</p>
        <p><img :src="require(`../../src/assets/images/${
          ['Introduced', 'Crossed over', 'Passed both chambers', 'Enacted', 'Vetoed', 'Veto Overridden', 'Dead'].includes(billData.current_legislative_status) ? billData.current_legislative_status : 'DefaultStatus'
        }.svg`)" class="status-icon"> {{ billData.current_legislative_status }}</p>
      </div>

      <div v-if="billData.last_amended">
        <p class="item-header">Latest Version</p>
        <p>{{ billData.last_amended }}</p>
      </div>

      <div>
        <p class="item-header">Number of votes</p>
        <p>{{ billData.number_of_votes }}</p>
      </div>  

      <div>
        <p class="item-header">Number of sponsors</p>
        <p>{{ billData.number_of_sponsors }}</p>
      </div>     
    </div>

    <div class="full-item-row" v-show="this.$isBallotpediaTracker">
       <a target="_blank" v-bind:href="billData.bill_track_link"><p class="item-header full-text">For more information, including the complete bill text and full legislative history, see BillTrack50.</p></a>
    </div>

  </div>

    <div class="bill-header">
        <p class="bill-header-text">Partisanship</p>
    </div>


  <div class="subheader-row">
    <div class="item-flex-row">
      <div>
        <p class="item-header">Sponsors' partisan affiliation(s)</p>
        <p class="partisan" v-bind:class="{ 
          'republican': billData.sponsors_partisan_affiliations === 'Republican' ? true : false ,
          'democratic': billData.sponsors_partisan_affiliations === 'Democratic' ? true : false 
          }" >{{ billData.sponsors_partisan_affiliations }}</p>
      </div>

      <div>
        <p class="item-header">State trifecta status</p>
        <p class="partisan" v-bind:class="{ 
          'republican': billData.trifecta_status === 'Republican' ? true : false ,
          'democratic': billData.trifecta_status === 'Democratic' ? true : false 
          }" >{{ billData.trifecta_status }}</p>
      </div>

    </div>
    </div>


    <div class="bill-header">
        <p class="bill-header-text">Action</p>
    </div>

    <div class="subheader-row">
    <div class="full-item-row">
      <p class="item-header">Most Recent Action</p>
      <p>{{ billData.most_recent_action }}</p>
    </div>

    <div class="item-flex-row">
      <div>
        <p class="item-header">Action date</p>
        <p>{{action_date}}</p>
      </div>

      <div>
        <p class="item-header">Days since last action</p>
        <p>{{ days_since }}</p>
      </div>

      </div>

  </div>

    <div class="bill-header">
        <p class="bill-header-text">Sponsors</p>
    </div>  

    <div class="subheader-row">
       <p v-for="sponsor, i in btdata.sponsors" :key="i">
        <strong>{{ sponsor.name }}</strong> ({{sponsor.legislatorParty}}) - {{ sponsor.role }}
       </p>
       <p v-if="btdata.sponsors.length < 1">No sponsors listed</p>
    </div>




    <div class="bill-header">
        <p class="bill-header-text">Bill summary</p>
    </div>

    <div class="subheader-row">
      <div class="summary-container">
          <p v-if="!this.billData.summary"><i>Bill summary pending</i></p>
          <div class="summary-text" v-if="this.billData.summary" v-html="this.billData.summary"></div>
      </div>
    </div>

    <div class="full-item-row" v-if="this.billData.show_disclaimer">
      <p>Note: This summary may not represent the most recent version of the bill. To view the current bill text and full legislative history, click the BillTrack50 link above.</p>
    </div>

  </div>
</template>

<script>
import moment from 'moment';
// Vue.prototype.moment = moment;

export default {
  name: "Bill",
  props: {},
  data() {
    return {
      loading: false,
      billData: null,
      btdata: null,
      categories: null,
      action_date: null,
      days_since: null
    };
  },
  // When component is created, get the Bill ID from the URL and make the API call to get data for single bill
  async created() {


    this.loading = true;
    let response = await fetch(
      `${process.env.VUE_APP_API4_DOMAIN}/legislation_tracking/bill`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(
            { 
              bill: this.$route.params.id
            }
          ),
      }
    );

    response = await response.json();

    if (response && response.success) {
      this.response = response;


      
      // Set data to local variable
      this.billData = response.data[0];
      
      console.log(this.billData);

      const topicPath = this.$topicPath;
      const currentYear = (new Date()).getFullYear();

      // Parse category CSV into array (if exists)
      response.data[0].categories ? this.categories = response.data[0].categories.split(",").map(cat => ({cat, link: `/${topicPath}/search?page=1&session=${currentYear}&category=${cat.trim()}`}) ) : '' ;
      
      // Action date format 
      this.action_date = moment(response.data[0].action_date).format('MMMM DD, YYYY');
      
      // Days since last action 
      this.days_since = moment().diff(moment(response.data[0].action_date), 'days');

      this.loading = false;
    } else {
      this.apiError = true;
      this.loading = false;
    }


    // Now, get supplementary data from BillTrack50 API 
    
    // Parse BT50's internal Bill ID from the Bill URL 
    let BTID = this.billData.bill_track_link.split('https://www.billtrack50.com/billdetail/')[1];

    BTID = BTID.split('/')[0]


      let btdata = await fetch(
        `${process.env.VUE_APP_API4_DOMAIN}/legislation_tracking/billtrack50`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(
            { 
              billID: BTID
            }
          ),
      }
    );

    btdata = await btdata.json();
    this.btdata = btdata.data;

    // Sort 
    if (this.btdata.sponsors) {

      this.btdata.sponsors.forEach((sponsor) => {
        sponsor.lastName = sponsor.name.split(" ").pop();
      })
    }

    this.btdata.sponsors.sort((a, b)=> {
    if (a.legislatorParty === b.legislatorParty){
      return a.lastName < b.lastName ? -1 : 1
      } else {
        return a.legislatorParty < b.legislatorParty ? -1 : 1
      }
    })


  



  },
    methods: {
    isBallotpediaTracker() {
      return !this.$route.query.embed && this.$isBallotpediaTracker
    },
    print() {
      window.print();
    },
    moment: function () {
      return moment();
    },
    stateAbbrToName(acronym) {
      const states = {
        AZ: 'Arizona',
        AL: 'Alabama',
        AK: 'Alaska',
        AR: 'Arkansas',
        CA: 'California',
        CO: 'Colorado',
        CT: 'Connecticut',
        DC: 'District of Columbia',
        DE: 'Delaware',
        FL: 'Florida',
        GA: 'Georgia',
        HI: 'Hawaii',
        ID: 'Idaho',
        IL: 'Illinois',
        IN: 'Indiana',
        IA: 'Iowa',
        KS: 'Kansas',
        KY: 'Kentucky',
        LA: 'Louisiana',
        ME: 'Maine',
        MD: 'Maryland',
        MA: 'Massachusetts',
        MI: 'Michigan',
        MN: 'Minnesota',
        MS: 'Mississippi',
        MO: 'Missouri',
        MT: 'Montana',
        NE: 'Nebraska',
        NV: 'Nevada',
        NH: 'New Hampshire',
        NJ: 'New Jersey',
        NM: 'New Mexico',
        NY: 'New York',
        NC: 'North Carolina',
        ND: 'North Dakota',
        OH: 'Ohio',
        OK: 'Oklahoma',
        OR: 'Oregon',
        PA: 'Pennsylvania',
        RI: 'Rhode Island',
        SC: 'South Carolina',
        SD: 'South Dakota',
        TN: 'Tennessee',
        TX: 'Texas',
        UT: 'Utah',
        VT: 'Vermont',
        VA: 'Virginia',
        WA: 'Washington',
        WV: 'West Virginia',
        WI: 'Wisconsin',
        WY: 'Wyoming',
        AS: 'American Samoa',
        GU: 'Guam',
        MP: 'Northern Mariana Islands',
        PR: 'Puerto Rico',
        VI: 'U.S. Virgin Islands',
        UM: 'U.S. Minor Outlying Islands',
      }
      return states[acronym];
    }
  },
};
</script>



<style scoped>

.print-only {
  display: none;
}

@media print {

  .print-hide {
    display: none;
  }

  .print-only {
    display: block;
  }
}

.bill-container {
  color: #444444;
  margin-left: 15%;
  margin-right: 15%;
  margin-top: 100px;
}

@media screen and (max-width: 1000px) {
  .bill-container {
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 100px;
  }
}

@media screen and (max-width: 768px) {
  .bill-container {
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 75px;
  }
}

.header-container {
  display: flex;
  align-items: center;
  gap: 30px;
}

.share-container {
  display: flex;
  justify-content: end;
  /* visibility: hidden; */
}

.share-icon-container {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.topics-intro {
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 5px;
}

.state_name {
  font-size: 30px;
  font-weight: 600;
}

.bill_number {
  font-size: 30px;
  font-weight: 700;
}

.bill_status {
  margin-top: 15px;
  margin-bottom: 15px;
}

.categories-container {
  display: inline-flex;
  justify-content: center;
  margin-right: 40px;
}

.state-img {
  height: 200px;
}

.cat-icon {
  margin-right: 10px;
  width: 14px;
}

.bill-header {
  margin-top: 50px;
  max-height: 45px;
  display: flex;
  align-content: center;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 5px;
  background: #e2eeff;
  background: #EDEDED;
  width: 500px;
  margin-bottom: 40px;
}

.bill-header-text {
  border-left: 15px solid #3047b1;
  height: 55px;
  display: flex;
  align-items: center;
  margin-left: 20px;
  padding-left: 20px;
  font-size: 22px;
  font-weight: 700;
}

.full-item-row {
  margin-bottom: 40px;
}

.subheader-row {
  padding-left: 20px;
}

.item-flex-row {
  display: flex;
  row-gap: 40px;
  column-gap: 70px;
  flex-wrap: wrap;
  /* justify-content: space-between; */
}

.item-header {
  text-transform: uppercase;
  font-weight: 700;
}

.full-text {
  text-transform: none;
  margin-top: 40px;
}

.summary-container {
  background: #e2eeff;
  padding: 40px;
  font-weight: 500;
  margin-bottom: 40px;
}

.republican {
  color: #AD0606;
}

.democratic {
  color: #32008B;
}

.partisan {
  font-weight: 600;
}
@media screen and (max-width: 768px) {
  .bill-header {
    width: 300px;
  }
}

@media screen and (max-width: 768px) {
  .header-container {
    flex-direction: column;
    align-items: center;
  }

  .bill-quickview {
    text-align: center;
  }

  .categories-container {
    margin-right: 10px;
  }

  .share-container {
    margin-bottom: 30px;
    justify-content: center;
  }
}
</style>
