<template>
    <div :class="{ 'search-container': !this.$route.query.embed }">

 
    
    <div v-show="!this.$route.query.embed">
       <div >
    <h2> Bill search </h2>
    <p>Use the input fields to search for bills. <span v-if="this.$isBallotpediaTracker">Click <a @click="unhideSearchList" class="pointer">here</a> to see a brief description of each field. For more information about using this database, including explanations of bill statuses and category tags, click <router-link :to="'faq'">here.</router-link> </span></p>
    <br />
    
    <ul class="search-list" v-if="showSearchList">
      <li><b>Keyword search:</b> Use this field to search a bill's official title or caption. For example, entering "amend" in this field will return every bill that mentions "amend" in either the official title or caption.   </li>
      <li><b>Bill number:</b> Use this field to search for a particular bill number. For example, entering "169" will yield any bill whose number includes that specific three-digit sequence (e.g., California AB1696). </li>
      <li><b>Most recent action:</b> Use this field to isolate bills whose most recent actions occurred within a specific date range. For example, to see all bills that were most recently acted upon between January 30 and February 15, enter those dates. Please note that this will only return bills that were last acted upon during that time period. A bill that saw some action on February 15 and subsequent action on a later date will not appear in the search results. </li>
      <li><b>State:</b> Use this field to search for bills within a particular state or group of states. Simply click on the state's name to add it to the search (multiple states can be selected). </li>
      <li><b>Current bill status:</b> Use this field to search bills by legislative status. Click on the status description to add it to the search (multiple status descriptions can be selected). For more information about status descriptions, <router-link :to="'faq'">click here</router-link>. </li>
      <li><b>Category:</b> Use this field to search bills by subject category. Click on the category to add it to the search (multiple categories can be selected). For more information about categories, <router-link :to="'faq'">click here</router-link>.</li>
       <br/>
    </ul>
   

    <div class="popular-searches" v-show="this.$isBallotpediaTracker">
    <p><strong>Popular searches</strong></p>
    <p><a :href="`search?status=Enacted&page=1`">Enacted bills</a></p>
    <p><a :href="`search?startDate=${lastWeekDate}&endDate=${todayDate}&page=1`">Bills acted upon in the past week</a></p>
    <p v-if="this.$topicPath === 'elections'"><a href="search?category=Absentee%2Fmail-in%20voting&page=1">Absentee/mail-in voting bills</a></p>
    <p><a :href="`search?status=Introduced&startDate=${lastWeekDate}&endDate=${todayDate}&page=1`">Introduced bills in the past week</a></p>
    <p><a :href="`search?status=Introduced&trifecta=Democratic&startDate=${lastMonthDate}&endDate=${todayDate}&page=1`">Bills introduced acted upon in D trifectas in the past month</a></p>
    <p><a :href="`search?status=Introduced&trifecta=Republican&startDate=${lastMonthDate}&endDate=${todayDate}&page=1`">Bills introduced acted upon in R trifectas in the past month</a></p>
    <p v-if="this.$topicPath === 'ai-deepfakes'"><a href="search?status=Enacted&status=Veto%20Overridden&category=Pornographic%20material&page=1">Enacted bills related to deepfakes and pornographic material</a></p>
    <p v-if="this.$topicPath === 'ai-deepfakes'"><a href="search?status=Enacted&status=Veto%20Overridden&category=Child%20sexual%20abuse%20material&page=1">Enacted bills related to deepfakes and child sexual abuse material</a></p>
    <p v-if="this.$topicPath === 'ai-deepfakes'"><a href="search?status=Enacted&status=Veto%20Overridden&category=Political%20communications&page=1">Enacted bills related to deepfakes and political communications</a></p>
    <p v-if="this.$topicPath === 'ai-deepfakes'"><a href="search?status=Enacted&status=Veto%20Overridden&category=Property%3B%20labor%3B%20or%20creative%20rights&page=1">Enacted bills related to deepfakes and property, labor, or creative rights</a></p>
    </div>

<!-- <p style="margin-bottom: 10px"><strong>Popular searches</strong></p>
<select name="forma" onchange="location = this.value;" class="popular-search">
 <option value="/search">Select popular search</option>
 <option :value="`/search?startDate=${lastWeekDate}&endDate=${todayDate}&page=1`">Bills acted upon in the past week</option>
 <option value="Sitemap.php">Sitemap</option>
</select> -->

<hr v-show="this.$isBallotpediaTracker">

    </div>
    </div>


<div class="top" v-show="!this.$route.query.embed">


  <!-- <p><strong>Custom search</strong></p> -->
 <!-- <hr class="nav-full"> -->
    <div class="input-parent">
      <!-- <p class="search-header">Search</p> -->
      <div class="inputs-container" >
        <label for="name-leg-app"><img src="../../src/assets/images/bill-icon.svg" class="input-icon"/> Keyword search </label>
        <input v-model="name" placeholder="Text in name" name="name-leg-app" />
      </div>

      <div class="inputs-container">
        <label for="number-leg-app"><img src="../../src/assets/images/bill-number-icon.svg" class="input-icon"/> Bill number </label>
        <input v-model="number" placeholder="Official bill #" name="number-leg-app" />
      </div>

      <div class="inputs-container">
        <label for="number"><img src="../../src/assets/images/calendar-icon.svg" class="input-icon"/> Most recent action</label>
        <!-- <input v-model="action_date" placeholder="YYYY-MM-DD" name="action_date" type="date" min="1997-01-01" max="2030-12-31"> -->

                <date-range-picker
            ref="picker"
            :opens="'true'"
            :locale-data="{ firstDay: 1, format: 'dd-mm-yy' }"
            :minDate="'2020-01-01'" :maxDate="'2030-01-01'"
            :singleDatePicker="false"
            :linkedCalendars="false"
            :showWeekNumbers="false"
            :showDropdowns="false"
            :autoApply="false"
            v-model="dateRange"
            @update="updateValues"
            @select="select"
     
    >
        <template v-slot:input="picker" style="min-width: 350px;">
            
            <span v-if="picker.startDate && picker.endDate">
            {{ picker.startDate | date }} - {{ picker.endDate | date }}
            </span>

            <span v-if="!picker.startDate || !picker.endDate">
              Date range
            </span>

            


            <!-- :dateFormat="dateFormat" -->
        </template>
    </date-range-picker>
      </div>

    
      <div class="inputs-container">
        <label for="state"><img src="../../src/assets/images/state-icon.svg" class="input-icon"/>State </label>
        <vue-multi-select
        v-model="state"
        :position="position"
        :options="options"
        :emptyTabText="''"
        :btnLabel="btnLabel('state')"
        :selectOptions="states" /> 
      </div>


      <div class="inputs-container">
        <label for="bill_status"><img src="../../src/assets/images/status-icon-2.svg" class="input-icon"/> Current bill status </label>
         <vue-multi-select
          v-model="status"
          :position="position"
          :options="options"
          :btnLabel="btnLabel('status')"
          :selectOptions="statuses" /> 
      </div>

      <div class="inputs-container">
        <label for="category"><img src="../../src/assets/images/tag-icon-light.svg" class="input-icon"/>Topic <router-link :to="{ path: 'faq', hash: '#definition' }"><span v-show="this.$isBallotpediaTracker" class="info-icon">(Topic definitions)</span></router-link></label>
          
          <vue-multi-select
          v-model="category"
          :position="position"
          :options="options"
          :btnLabel="btnLabel('category')"
          :selectOptions="categories" /> 

      </div>

      <div class="inputs-container">
        <label for="sponsor"><img src="../../src/assets/images/tag-icon-light.svg" class="input-icon"/>Sponsor Party</label>
          
          <vue-multi-select
          v-model="sponsor"
          :position="position"
          :options="options"
          :btnLabel="btnLabel('sponsor')"
          :selectOptions="sponsors" /> 

      </div>
      

      <div class="inputs-container">
        <label for="trifecta"><img src="../../src/assets/images/tag-icon-light.svg" class="input-icon"/>Trifecta status</label>
          
          <vue-multi-select
          v-model="trifecta"
          :position="position"
          :options="options"
          :btnLabel="btnLabel('trifecta')"
          :selectOptions="trifectas" /> 

      </div>

      <div class="inputs-container">
        <label for="orderby"><img src="../../src/assets/images/tag-icon-light.svg" class="input-icon"/>Order by</label>
          <select name="orderby" v-model="orderby" class="select-single">
            <option value="">Default</option>
            <option value="stateAsc">State (+)</option>
            <option value="stateDesc">State (-)</option>
            <option value="billAsc">Bill number (+)</option>
            <option value="billDesc">Bill number (-)</option>
            <option value="nameAsc">Official name (+)</option>
            <option value="nameDesc">Official name (-)</option>
            <option value="actionAsc">Most recent action (+)</option>
            <option value="actionDesc">Most recent action (-)</option>
            <option value="statusAsc">Status (+)</option>
            <option value="statusDesc">Status (-)</option>
          </select>
      </div>

       <div class="inputs-container">
        <label for="session"><img src="../../src/assets/images/tag-icon-light.svg" class="input-icon"/>Session year</label>
          
          <vue-multi-select
          v-model="session"
          :position="position"
          :options="options"
          :btnLabel="btnLabel('session')"
          :selectOptions="sessions" /> 

      </div>

      <!-- <select name="forma" onchange="location = this.value;" class="popular-search">
        <option value="/search">Select popular search</option>
        <option :value="`/search?startDate=${lastWeekDate}&endDate=${todayDate}&page=1`">Bills acted upon in the past week</option>
        <option value="Sitemap.php">Sitemap</option>
      </select> -->


      </div>

      <button class="search" v-bind:class="{ 'custom-btn': this.$isBallotpediaTracker == false }" v-on:click="search(null)"><img src="../../src/assets/images/search-icon-white.svg" class="search-icon"> Search</button>
      <button class="copy" v-on:click="clearSearch()"><img src="../../src/assets/images/trash.svg" class="search-icon"> Clear search</button>
      <button v-show="this.$isBallotpediaTracker" class="copy" v-bind:class="{'clicked': copyClicked}" v-on:click="copy()"><img v-if="!copyClicked" src="../../src/assets/images/search-icon.svg" class="search-icon"> {{ copyText }}</button>
  
 

    <!-- <button class="search" v-on:click="search(null)"><img src="../../src/assets/images/search-icon-white.svg" class="search-icon"> Search</button> -->
    <!-- <button class="copy" v-bind:class="{'clicked': copyClicked}" v-on:click="copy()"><img v-if="!copyClicked" src="../../src/assets/images/search-icon.svg" class="search-icon"> {{ copyText }}</button> -->
    

</div>

    <!-- <div style="height: 300px"></div> -->

    <div id="table-container" >
       <p v-bind:class="{ 'invisible': loading }" style="text-align: center; font-weight: bold;"><span>{{ this.total_count > 0 ? `${this.total_count > 1 ? `This search result found ${ this.total_count } bills` : `This search result found ${ this.total_count }  bill` }`   : '' }}</span><span v-show="!loading && !this.total_count" style="text-align: center; font-weight: bold">This search result found 0 bills</span>.</p>
      
      <p  style="text-align: right; padding-right: 10px; padding-bottom: 10px;">
        <span v-if="this.total_count > 0"  >{{this.start_index + 1}} - {{this.end_index + 1}} of</span>
        
          {{ this.total_count }}

        <span v-on:click="search('prev')" v-if="total_count > 100"> <img src="../../src/assets/images/left-arrow.svg" class="pagination-icon"/></span>
          
        <span v-on:click="search('next')" v-if="total_count > 100"> <img src="../../src/assets/images/right-arrow.svg" class="pagination-icon"/></span>
      </p>


    <!-- Responsive table starts here -->
    <!-- For correct display on small screens you must add 'data-title' to each 'td' in your table -->
    <div class="table-responsive-vertical shadow-z-1 tableFixHead" >
    <!-- Table starts here -->
    <table id="table" class="table table-hover table-mc-light-blue">
        <thead style="width: 100%">
          <tr>
            <th class="five-col">State</th>
            <th class="fifteen-col">Bill number</th>
            <th class="fifty-col">Official name</th>
            <th class="fifteen-col">Most recent action</th>
            <th class="fifteen-col">Status</th>
            <th class="fifteen-col">Topics</th>
          </tr>
        </thead>
        <tbody style="width: 100%">
          <tr v-for="bill in results" :key="bill.id" v-on:click="billClicked(bill.id)">
            <td class="five-col" data-title="state">{{ bill.state }}</td>
            <td class="fifteen-col" data-title="bill">{{ bill.bill_number }}</td>
            <td class="fifty-col" data-title="name">{{ bill.name }}</td>
            <td class="fifteen-col" data-title="date">{{ bill.action_date }}</td>
            <td class="fifteen-col" data-title="status"><img :src="require(`../../src/assets/images/${
              ['Introduced', 'Crossed over', 'Passed both chambers', 'Enacted', 'Vetoed', 'Veto Overridden', 'Dead'].includes(bill.current_legislative_status) ? bill.current_legislative_status : 'DefaultStatus'
            }.svg`)" class="status-icon"> {{ bill.current_legislative_status }}</td>
            <td class="fifteen-col" data-title="status" style="font-size: 12px">{{ bill.categories ? bill.categories.replaceAll(',', ', ') : '' }}</td>
          </tr>

          <tr v-if="results.length < 1 && !loading">
            <td colspan="6" style="width: 100%; text-align: center; padding-bottom: 300px">{{ this.paramsExist ? '' : ''}}</td>
          </tr>
          
          <tr v-if="loading">
            <td colspan="6" style="width: 100%; text-align: center; padding-bottom: 300px"><div class="lds-dual-ring"></div></td>
          </tr>

        </tbody>
      </table>
    </div>

  </div>
    




    <!-- For debugging, uncomment below to see JSON payload and JSON response -->
    
    <!-- <h3>JSON to send to backend for search</h3>
    <pre>{{ payload }}</pre>

    <h3>Response</h3>
     <pre>{{ response }}</pre> -->



  </div>
</template>

<script>
import moment from 'moment';
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import vueMultiSelect from 'vue-multi-select';
import 'vue-multi-select/dist/lib/vue-multi-select.css';
export default {
  name: "Search",
  components: {vueMultiSelect, DateRangePicker},
  props: {},
  filters: {
    date(val) {
    return val ? moment(val).utc().format("YYYY-MM-DD") : "";
    }
  },

  data() {
    return {
      state: [],
      states: ['AL','AK','AZ','AR','CA','CO','CT', 'DC', 'DE','FL','GA',
              'HI','ID','IL','IN','IA','KS','KY','LA','ME','MD','MA',
              'MI','MN','MS','MO','MT','NE','NV','NH','NJ','NM','NY','NC','ND',
              'OH','OK','OR','PA','RI','SC','SD','TN','TX','UT',
              'VT','VA','WA','WV','WI','WY'],
      status: [],
      // the statuses array is repeated above in <td class="fifteen-col" data-title="status">
      // it's used to default to DefaultStatus when an unknown status appears
      // because I couldn't get a view method to work correctly
      // the same inlining of the default value is used twice in Bill.vue
      statuses: ["Introduced", "Crossed over", "Passed both chambers", "Enacted", "Vetoed", "Veto Overridden", "Dead"],
      category: [],
      categories: [],
      sponsor: [],
      sponsors: ['Democratic', 'Republican', 'Bipartisan', 'Other'],
      trifecta: [],
      trifectas: ['Democratic', 'Republican', 'Divided'],
      orderby: '',
      orderbys: [
        {val: "", label: "Default"},
        {val: "stateAsc", label: "State (+)"},
        {val: "stateDesc", label: "State (-)"},
        {val: "billAsc", label: "Bill number (+)"},
        {val: "billDesc", label: "Bill number (-)"},
        {val: "nameAsc", label: "Official name (+)"},
        {val: "nameDesc", label: "Official name (-)"},
        {val: "actionAsc", label: "Most recent action (+)"},
        {val: "actionDesc", label: "Most recent action (-)"},
        {val: "statusAsc", label: "Status (+)"},
        {val: "statusDesc", label: "Status (-)"},
      ],
      name: "",
      number: "",
      action_date: '',
      payload: '',
      page: 1,
      session: [(new Date().getFullYear()).toString()],
      sessions: this.getAvailableSessionYears(),
      apiError: "false",
      loading: false,
      copyText: 'Copy link',
      copyClicked: false,
      response: '',
      results: '',
      total_count: '',
      start_index: 0,
      end_index: 0,
      end_page: 0,
      paramsExist: null,
      showTable: true,
      direction: 'ltr',
      format: 'mm/dd/yyyy',
      separator: ' - ',
      applyLabel: 'Apply',
      cancelLabel: 'Cancel',
      weekLabel: 'W',
      customRangeLabel: 'Custom Range',
      daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      firstDay: 0,
      dateRange: {
        startDate: null,
        endDate: null
      },
      todayDate: moment().utc().format('YYYY-MM-DD'),
      lastWeekDate: moment().utc().subtract(7, 'days').format('YYYY-MM-DD'),
      lastMonthDate: moment().utc().subtract(30, 'days').format('YYYY-MM-DD'),
      showSearchList: false,

      select: (event) => {

        if (event.startDate && event.endDate) {
          this.dateRange.startDate = moment(event.startDate).utc().format("YYYY-MM-DD");
          this.dateRange.endDate = moment(event.endDate).utc().format("YYYY-MM-DD");
        }

      },

      updateValues: () => {

        this.dateRange.startDate = this.dateRange.startDate ? moment(this.dateRange.startDate).utc().format("YYYY-MM-DD") : null;
        this.dateRange.endDate = this.dateRange.endDate ? moment(this.dateRange.endDate).utc().format("YYYY-MM-DD") : null;
      },

      btnLabel: (select) => {

        if (select == 'state') {
          if(this.state.length == 0) {
            return ()=>('All states');
          } else if (this.state.length == 1) {
            return ()=>(this.state[0]);
          } else if (this.state.length > 1) {
            return ()=>('Multiple chosen');
          }
        }

        if (select == 'status') {
          if(this.status.length == 0) {
            return ()=>('All statuses');
          } else if (this.status.length == 1) {
            return () => {
              if (this.status[0].length > 12) {
                return `${this.status[0].substring(0, 12)}..`
              } else {
                return this.status[0]
              }
            }
  
          } else if (this.status.length > 1) {
            return ()=>('Multiple chosen');
          }
        }

        if (select == 'category') {
          if(this.category.length == 0) {
            return ()=>('All topics');
          } else if (this.category.length == 1) {
            return () => {
              if (this.category[0].length > 12) {
                return `${this.category[0].substring(0, 12)}..`
              } else {
                return this.category[0]
              }
            }
          } else if (this.category.length > 1) {
            return ()=>('Multiple chosen');
          }
        }

        if (select == 'sponsor') {
          if(this.sponsor.length == 0) {
            return ()=>('All sponsors');
          } else if (this.sponsor.length == 1) {
            return () => {
              if (this.sponsor[0].length > 12) {
                return `${this.sponsor[0].substring(0, 12)}..`
              } else {
                return this.sponsor[0]
              }
            }
          } else if (this.sponsor.length > 1) {
            return ()=>('Multiple chosen');
          }
        }


        if (select == 'trifecta') {
          if(this.trifecta.length == 0) {
            return ()=>('All');
          } else if (this.trifecta.length == 1) {
            return () => {
              if (this.trifecta[0].length > 12) {
                return `${this.trifecta[0].substring(0, 12)}..`
              } else {
                return this.trifecta[0]
              }
            }
          } else if (this.trifecta.length > 1) {
            return ()=>('Multiple chosen');
          }
        }


        if (select == 'session') {
          if(this.session.length == 0) {
            return ()=>('All');
          } else if (this.session.length == 1) {
            return () => {
              if (this.session[0].length > 12) {
                return `${this.session[0].substring(0, 12)}..`
              } else {
                return this.session[0]
              }
            }
          } else if (this.session.length > 1) {
            return ()=>('Multiple chosen');
          }
        }


      },
      position: 'bottom-left',
      options: {
        multi: true,
        groups: false,
        labelList: 'elements',
        cssSelected: option => (option.selected ? { 'background-color': '#e2eeff' } : ''),
      },
    };
  },

  async beforeMount() {

    this.loading = true;

    if (this.$route.fullPath === '/search') {
      this.showTable = false;
    }



   
 
    // This 'created()' function is called every time the component is navigated to or when the route URL params change.
    // We do the search here because after the user clicks search, the URL is updated which forces the component to be re-created.




    // If no params in the URL, such as when navigating the /search page directly, 
    // Then add "page=1" and "session=CURRENT_YEAR" to the URL so the default search will execute 

    if (Object.keys(this.$route.query).length == 0 || Object.keys(this.$route.query).length == 1) {

      
      this.page = this.$route.query.page ? this.$route.query.page : this.page;

      this.$router.replace({ query: { page: this.page, session: this.session }})


      // else if param is only the page and session, do default search for all bills. Or if the embed=true param is also true.
   } else if ((Object.keys(this.$route.query).length == 2 && this.$route.query.page && this.$route.query.session) || (Object.keys(this.$route.query).length == 3 && this.$route.query.page && this.$route.query.session && this.$route.query.embed)) {




      // Default search object
      let search = {};

      // If exists in URL, override app defaults, such as if someone linked to just the basic /search&page=3
      this.page = this.$route.query.page ? this.$route.query.page : this.page;
      

      if(!Array.isArray(this.$route.query.session)) {
        this.session = [this.$route.query.session]
      } else if (this.$route.query.session) {
        this.session = this.$route.query.session;
      }

     // this.session = this.$route.query.session ? this.$route.query.session : this.session;

      
        
      search.session = this.session;  
      search.page = this.page;
      search.topic_area = this.$topic; // Topic area /elections, /esg, etc. 
      console.log(this.$topic);

      this.payload = JSON.stringify(search);
      
      // console.log("********** PAYLOAD ***********");
      // console.log(this.payload);


      let response = await fetch(`${process.env.VUE_APP_API4_DOMAIN}/legislation_tracking/categories`, {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
         body: JSON.stringify(
          { 
            topic_area: this.$topic
          }
        )
        })


        response = await response.json();

        if (response && response.success) {

          //console.log(response);

          let parentCategories = [];
          response.data.forEach((item) => {

            let name = item.name;
            
            if(!parentCategories.includes(item.parent_category)) {

              //this.categories.indexOf(item.parent_category) === -1 ? this.categories.push(`Parent-${item.parent_category}`) : '';
            
              this.categories.push(`Parent-${item.parent_category}`)
              parentCategories.push(item.parent_category);
            }
            
                this.categories.push(name);
            
          })

          this.categories.splice(0, this.category.length);

          // this.categories.sort();

      
        } else {
          this.apiError = true;
        }


      response = await fetch(`${process.env.VUE_APP_API4_DOMAIN}/legislation_tracking/all_bills`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(search)
        })

        response = await response.json();

        if (response && response.success) {

          this.response = response;
          this.results = response.data;

          if(this.results.length > 0) {
            this.total_count = this.results[0]['total_count'];
            this.paginate(this.total_count, this.page, 100, 100);
          }


          this.loading = false;
        } else {
          this.apiError = true;
          this.loading = false;
      }



    }

    // Else If there are search params in the URL, do a search according to those. (This allows bookmarking)
    else if (Object.keys(this.$route.query).length !== 0) {

      this.loading = true;

      // First, pre-fill our inputs in the interface with the values from the URL params, if they exist.

      // For multi select, need to convert the string into array if just one item selected
     if (this.$route.query.state) {
        if (Array.isArray(this.$route.query.state)) {
          this.state = this.$route.query.state
        } else {
          this.state = this.$route.query.state.split();
        }
      }

      if (this.$route.query.status) {
        if (Array.isArray(this.$route.query.status)) {
          this.status = this.$route.query.status
        } else {
          this.status = this.$route.query.status.split();
        }
      }

      if (this.$route.query.category) {
        if (Array.isArray(this.$route.query.category)) {
          this.categories = this.$route.query.category
          this.category = this.$route.query.category

        } else {
          this.categories = this.$route.query.category.split();
          this.category = this.$route.query.category.split();
          
        }

      }

      if (this.$route.query.sponsor) {
        if (Array.isArray(this.$route.query.sponsor)) {
          this.sponsor = this.$route.query.sponsor
        } else {
          this.sponsor = this.$route.query.sponsor.split();
        }
      }

      if (this.$route.query.trifecta) {
        if (Array.isArray(this.$route.query.trifecta)) {
          this.trifecta = this.$route.query.trifecta
        } else {
          this.trifecta = this.$route.query.trifecta.split();
        }
      }

      
      if (this.$route.query.session) {
        if (Array.isArray(this.$route.query.session)) {
          this.session = this.$route.query.session
        } else {
          this.session = this.$route.query.session.split();
        }
      }

      
      // The remaining are not arrays 
      this.name = this.$route.query.name ? this.$route.query.name : this.name;
      this.number = this.$route.query.number ? this.$route.query.number : this.number;
      this.action_date = this.$route.query.action_date ? this.$route.query.action_date : this.action_date;
      this.page = this.$route.query.page ? this.$route.query.page : this.page;

      this.dateRange.startDate = this.$route.query.startDate ? this.$route.query.startDate : this.dateRange.startDate;
      this.dateRange.endDate = this.$route.query.endDate ? this.$route.query.endDate : this.dateRange.endDate;

      this.orderby = this.$route.query.orderby ? this.$route.query.orderby : this.orderby;




      let response = await fetch(`${process.env.VUE_APP_API4_DOMAIN}/legislation_tracking/categories`, {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
          body: JSON.stringify(
          { 
            topic_area: this.$topic
          }
        )
        })

        response = await response.json();

        if (response && response.success) {

          //console.log(response);

          let parentCategories = [];
          response.data.forEach((item) => {

            let name = item.name;
            
            if(!parentCategories.includes(item.parent_category)) {

              //this.categories.indexOf(item.parent_category) === -1 ? this.categories.push(`Parent-${item.parent_category}`) : console.log('positive');
          
              this.categories.push(`Parent-${item.parent_category}`)
              parentCategories.push(item.parent_category);
            }
            
                this.categories.push(name);
            
          })

          this.categories.splice(0, this.category.length);

          // this.categories.sort();

      
        } else {
          this.loading = false;
          this.apiError = true;
        }
    





      // Now create the object for the backend
      // Only add to search object if there is a value or if not set to default value (That way we only send what we need to the backend)
      let search = {};

      this.state && this.state.length > 0 && (search.state = this.state);
      this.status && this.status.length > 0 && (search.status = this.status);
      this.name && (search.name = this.name.trim());
      this.number && (search.number = this.number.trim());
      this.category && this.category.length > 0 && (search.category = this.category);
      this.dateRange.startDate && (search.startDate = this.dateRange.startDate);
      this.dateRange.endDate && (search.endDate = this.dateRange.endDate);

      this.sponsor && this.sponsor.length > 0 && (search.sponsor = this.sponsor);

      this.trifecta && this.trifecta.length > 0 && (search.trifecta = this.trifecta);
      
      this.orderby && this.orderby.length > 0 && (search.orderby = this.orderby);

      // There will always be a page
      search.page = this.page;

      // There will always be a topic area
      search.topic_area = this.$topic;

      // There will always be a session year
      search.session = this.session;

      this.payload = JSON.stringify(search);
      
      // console.log("********** PAYLOAD ***********");
    //  console.log(this.payload);
    
      
      
      // Hit API with search object

      response = await fetch(`${process.env.VUE_APP_API4_DOMAIN}/legislation_tracking/search`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        body: JSON.stringify(search)
        })

        response = await response.json();

        if (response && response.success) {

          this.response = response;
          this.results = response.data;

          if(this.results.length > 0) {
            this.total_count = this.results[0]['total_count'];
            this.paginate(this.total_count, this.page, 100, 100);
          }


          this.loading = false;
        } else {
          this.apiError = true;
          this.loading = false;
      }

      this.paramsExist = true;
    } else {
      // If no URL params
      this.paramsExist = false;

        let response = await fetch(`${process.env.VUE_APP_API4_DOMAIN}/legislation_tracking/categories`, {
          method: 'GET',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
          body: JSON.stringify(
          { 
            topic_area: this.$topic
          }
        )
        })

        response = await response.json();

        if (response && response.success) {

          //console.log(response);

          let parentCategories = [];
          response.data.forEach((item) => {

            let name = item.name;
            
            if(!parentCategories.includes(item.parent_category)) {

              //this.categories.indexOf(item.parent_category) === -1 ? this.categories.push(`Parent-${item.parent_category}`) : '';
          
              this.categories.push(`Parent-${item.parent_category}`)
              parentCategories.push(item.parent_category);
            }
            
                this.categories.push(name);
            
          })

          this.categories.splice(0, this.category.length);

          // this.categories.sort();

      
        } else {
          this.loading = false;
          this.apiError = true;
        }
    }



  },
  mounted() {
    //console.log(this.todayDate);
    //console.log(this.lastWeekDate);
    //console.log(this.lastMonthDate);
  },
  methods: {

    getAvailableSessionYears() {
      //update to use session year var set in main.js
      return this.$sessionYears;
      /*
      if (this.$topicPath === 'esg') {
        return ['2024', '2023', '2022', '2021', '2020'];
      } else if (this.$topicPath === 'elections') {
        return ['2024', '2023', '2022'];
      } else if (this.$topicPath === 'admin-state') {
        return ['2024'];
      } else {
        return ['2024'] 
      }
        */
    },


    unhideSearchList() {
      this.showSearchList = !this.showSearchList;
    },

    moment: function () {
      return moment();
    },
    clearSearch() {
      this.results = '';
      this.status = [];
      this.state = [];
      this.category = [];
      this.total_count = 0;
      this.name = '';
      this.number = '';
      this.dateRange.startDate = null;
      this.dateRange.endDate = null;
      this.sponsor = [];
      this.trifecta = [];
      this.orderby = '';

       history.pushState(
    {},
    null,
    this.$route.path +
      '?' +
      Object.keys({})
        .map(key => {
          return (
            encodeURIComponent(key) + '=' + encodeURIComponent({}[key])
          )
        })
        .join('&')
  )
    },

    search(page_action) {

      // This function is for reading the values from the search inputs and appending those search values to the URL as parameters. 
      // This allows for sharing searches via the URL or bookmarking searches via the URL. 

      // After the URL updates, the "created()" function (above) is called.
      // This happens because whenever a URL changes with Vue, the component is re-rendered. 


      // Create Object for our data
      let urlParams = {};

      // Add values from the input to the it to URL params object
      // Do not add to the object if the input is not filled out or the input is at its default state (as these are not needed and keeps the URL cleaner)

      this.state && this.state.length > 0 && (urlParams.state = this.state);
      this.status && this.status.length > 0 && (urlParams.status = this.status);
      this.name && (urlParams.name = this.name);
      this.category && this.category.length > 0 && (urlParams.category = this.category);
      this.number && (urlParams.number = this.number);

      this.sponsor && this.sponsor.length > 0 && (urlParams.sponsor = this.sponsor);

      this.trifecta && this.trifecta.length > 0 && (urlParams.trifecta = this.trifecta);

      if (this.$route.query.embed) {
        urlParams.embed = this.$route.query.embed;
      }

      if (this.orderby && this.orderby.length > 0) {
        urlParams.orderby = this.orderby;
      }

      this.dateRange.startDate && (urlParams.startDate = this.dateRange.startDate);
      this.dateRange.endDate && (urlParams.endDate = this.dateRange.endDate);


      this.session && this.session.length > 0 && (urlParams.session = this.session);


      // If "next" or "previous" is passed to this function, we also add the page number to the URL

      if(page_action == 'prev' && this.page != 1) {
        this.page = parseInt(this.page) - 1;
      } else if (page_action == 'next' && this.page != this.end_page) {
        this.page = parseInt(this.page) + 1;
      }

      urlParams.page = this.page;

      // If 'page_action' is null, it's implied this function was called from the search button, not pagination, so reset page to 1
      if (!page_action) {
        this.page = 1;
        urlParams.page = this.page;
      }

      
      // Now append the params to URL
      // After this, the "created()" function will be called since we updated the URL and the component is re-created.
      // From there, the "created()" function will handle the API call to get the data depending on what is in the URL parameters.
      this.$router.push({ query: urlParams, params: { savePosition: true } });

     
    },

    // Logic for pagination 
    // From here: https://jasonwatmore.com/post/2018/08/07/javascript-pure-pagination-logic-in-vanilla-js-typescript
    paginate(totalItems, currentPage, pageSize, maxPages) {
        // calculate total pages
        let totalPages = Math.ceil(totalItems / pageSize);

        // ensure current page isn't out of range
        if (currentPage < 1) {
            currentPage = 1;
        } else if (currentPage > totalPages) {
            currentPage = totalPages;
        }

        let startPage, endPage;
        if (totalPages <= maxPages) {
            // total pages less than max so show all pages
            startPage = 1;
            endPage = totalPages;
        } else {
            // total pages more than max so calculate start and end pages
            let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
            let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
            if (currentPage <= maxPagesBeforeCurrentPage) {
                // current page near the start
                startPage = 1;
                endPage = maxPages;
            } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
                // current page near the end
                startPage = totalPages - maxPages + 1;
                endPage = totalPages;
            } else {
                // current page somewhere in the middle
                startPage = currentPage - maxPagesBeforeCurrentPage;
                endPage = currentPage + maxPagesAfterCurrentPage;
            }
        }

        // calculate start and end item indexes
        let startIndex = (currentPage - 1) * pageSize;
        let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

        // create an array of pages to ng-repeat in the pager control
        let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);

        // return object with all pager properties required by the view

        this.start_index = startIndex;
        this.end_index = endIndex;
        this.end_page = endPage;

        return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            pages: pages
        };
    },
    billClicked(id) {

      if (this.$isBallotpediaTracker) {
          window.open(`bill/${id}`, '_blank');
      } else {
          window.open(`bill/${id}`, '_self');
      }


    },
    copy() {
      let temp = document.createElement('input'),
      text = window.location.href;
      document.body.appendChild(temp);
      temp.value = text;
      temp.select();
      document.execCommand('copy');
      document.body.removeChild(temp);
      this.copyClicked = true;
      this.copyClicked = true;
      this.copyText = "Copied!";
      setTimeout(function () {
        this.copyClicked = false;
        this.copyText = "Copy link";
      }.bind(this), 1000);
  }

  }


};
</script>


<style >


.info-icon {
  font-size: 12px;
  font-weight: 400;
  color: #337ab7;
  margin-left: 5px;
  cursor: pointer;
}

.selectItem {
  margin-left: 10px;
}

.disabled {
  color: black !important;
  font-weight: 700;
  margin-left: 0px !important;
  cursor: pointer !important;
}


.pointer {
  cursor: pointer;
}
.popular-search {
  width: 500px;
}

.popular-searches p {
  font-weight: 500;
  font-size: 16px;
}

.search-list {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 15px;
}

.reportrange-text {
  overflow: unset !important;
  padding: 4.5px 10px 4.5px 10px;
  height: auto;
}

.vue-daterange-picker {
  width: 195px;
}


.invisible {
  visibility: hidden !important;
}

.btn-select {
  width: 195px !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}


th {
  background: #f8f8f8 !important;
}

.search-container {
  padding-right: 120px ;
  padding-left: 120px;
  margin-top: 150px;
}

.fifty-col {
  width: 35%;
}

.fifteen-col {
  width: 15%; 
}

.five-col {
  width: 5%;
}

.status-icon {
  height: 8px;
  margin-right: 5px;
  /* display: none; */
}

.pagination-icon {
  padding-right: 10px;
  padding-left: 10px;
}

.pagination-icon:hover {
  cursor: pointer;
}

h3 {
  margin-bottom: 20px;
}

.input-icon {
  margin-right: 5px;
  height: 15px;
  position: relative;
  top: 3px;
  display: none;
}
.inputs-container {
  display: inline-block;
  margin-right: 20px;
  margin-top: 10px;
}

label {
  font-size: 14px;
  font-weight: 600;
  display: flex;
}
select,
input {
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-weight: 500;
  padding-left: 10px;
  width: 195px;
  height: 31px;
}

::placeholder {
  color: #9E9D9D;
  font-style: italic;
  font-weight: 500;
}

.search {
  width: 195px;
  /* display: block; */
  color: white;
  background: #3047B1;
  font-weight: 500;
  text-align: center;
  margin-top: 20px;
  border-radius: 5px;
  border: none;
  height: 34px;
  transition: .25s;
  margin-top: 20px;
  font-size: 16px;
  display: inline-block;
}

.search:hover {
  background: #162B8C;
}


.copy {
  width: 195px;
  display: block;
  color: black;
  background: white;
  font-weight: 500;
  text-align: center;
  margin-top: 20px;
  border-radius: 5px;
  border: none;
  height: 34px;
  margin-top: 20px;
  font-size: 16px;
  display: inline-block;
  border: 1px solid #ccc;
  margin-left: 20px;
  background: #f8f8f8;
}

.clicked {
  background: #28a745;
  color: white;
}

.search-icon {
  height: 12px;
  position: relative;
  right: 5px;
  bottom: 2px;
}
#table-container {
  margin: 20px auto;
  font-size: 14px;
  font-weight: 500;
  position: relative;
}
.shadow-z-1 {
  border: 1px solid #ccc;
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 2rem;
  background-color: #ffffff;
}
.table > thead > tr,
.table > tbody > tr,
.table > tfoot > tr {
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  text-align: left;
  padding: 1.6rem;
  vertical-align: top;
  border-top: 0;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.table > thead > tr > th {
  font-weight: 600;
  vertical-align: bottom;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.table > caption + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > th,
.table > thead:first-child > tr:first-child > th,
.table > caption + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > td,
.table > thead:first-child > tr:first-child > td {
  border-top: 0;
}
.table > tbody + tbody {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.table .table {
  background-color: #ffffff;
}
.table .no-border {
  border: 0;
}
.table-condensed > thead > tr > th,
.table-condensed > tbody > tr > th,
.table-condensed > tfoot > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > td {
  padding: 0.8rem;
}
.table-bordered {
  border: 0;
}
.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
  border: 0;
  border-bottom: 1px solid #e0e0e0;
}
.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
  border-bottom-width: 2px;
}
.table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
  background-color: #f5f5f5;
}


.table-hover > tbody > tr:hover > td,
.table-hover > tbody > tr:hover > th {
  background-color: transparent;
  cursor: pointer;
}

/* To highlight every other row  */
table tbody tr:nth-of-type(even) {
    background-color: #e2eeff;
}

/*  Spinner  */

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #000;
  border-color: #3F51B5 transparent #3F51B5 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.esg-search {
  background: #BE1E2D !important;
}

/* Responsive  */

@media screen and (max-width: 1020px) {
    .search-container {
    padding-right: 40px;
    padding-left: 40px;
  }

}

@media screen and (max-width: 768px) {

  .select, .btn-select {
    width: 100% !important;
    font-size: 20px !important;
  }

  ul .selectItem {
    font-size: 16px !important;
  }


  .selectItem {
    white-space: normal !important;
  }

  label {
    font-size: 20px;
  }
  
  input, select {
    height: 40px;
  }

  .fifty-col {
    width: 100%;
  }

  .fifteen-col {
    width: 100%; 
  }

  .five-col {
    width: 100%;
  }
  .inputs-container {
    margin: 0 auto;
    display: block;
    margin-top: 20px;
  }
  .inputs-container input, .inputs-container select {
    width: 100%;
    font-size: 20px;
  }

  .search-container {
    padding-right: 20px !important;
    padding-left: 20px !important;
    margin-top: 100px;
  }

  .search, .copy {
    width: 100%;
    margin-left: 0;
    font-size: 20px;
    padding-top: 10px;
    height: auto;
    font-weight: bold;
    padding-bottom: 10px;
  }

  .copy {
    font-weight: 400;
  } 
  .input-icon {
    display: none;
  }
  .tableFixHead { 
    overflow: auto; 
    height: 400px;   
  }
  .tableFixHead thead th { 
    position: static; 
    top: 0; 
    z-index: 9999; 
    background: white;
  }

  #table-container {
    margin-right: 0;
  }

  .table-responsive-vertical > .table {
    margin-bottom: 0;
    background-color: #3047b1;
  }
  .table-responsive-vertical > .table > thead,
  .table-responsive-vertical > .table > tfoot {
    display: none;
  }
  .table-responsive-vertical > .table > tbody {
    display: block;
  }
  .table-responsive-vertical > .table > tbody > tr {
    display: block;
    border: 1px solid #e0e0e0;
    border-radius: 2px;
    margin-bottom: 1.6rem;
  }
  .table-responsive-vertical .table > tbody > tr > td {
    padding-top: 0px;
  }

  .table-responsive-vertical .table > tbody > tr > td:first-child{
    padding-top: 20px;
    font-weight: 700;
    font-size: 20px;
  }
  .table-responsive-vertical > .table > tbody > tr > td {
    background-color: #ffffff;
    display: block;
    vertical-align: middle;
    text-align: left;
    font-weight: 600;
  }
  .table-responsive-vertical > .table > tbody > tr > td[data-title]:beforedl {
    content: attr(data-title);
    /* float: left; */
    font-size: inherit;
    font-weight: 400;
    color: #757575;
    margin-bottom: 20px;
  }

  .table-responsive-vertical.shadow-z-1 {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  .table-responsive-vertical.shadow-z-1 > .table > tbody > tr {
    border: none;
    -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
    -moz-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
  }
  .table-responsive-vertical > .table-bordered {
    border: 0;
  }
  .table-responsive-vertical > .table-bordered > tbody > tr > td {
    border: 0;
    border-bottom: 1px solid #e0e0e0;
  }
  .table-responsive-vertical > .table-bordered > tbody > tr > td:last-child {
    border-bottom: 0;
    background: blue;
  }

  .vue-daterange-picker {
    width: 100% !important;
    font-size: 20px;
  }
  .reportrange-text {
    font-size: 20px;
  }
}

.tableFixHead { 
  overflow: auto; height: 400px;   
}
.tableFixHead thead th { 
  position: sticky; 
  top: 0; 
  z-index: 1; 
  background: white
}

/* .top {
  padding: 20px;
    background: #F7F6F6;
    border: 1px solid #ccc;
    border-radius: 5px 5px 0 0px;
    border-bottom: none;
} */

</style>
