<template>
  <div class="home-container" >


  <div v-if="this.$isBallotpediaTracker" >
      <img  :src="require(`../../src/assets/images/${this.$trackerLogo}`)" class="header-logo" :alt="this.$topicNameFull + ' legislation tracking'">
    </div>

      
    <!-- Elections, Admin State -->

    <div v-if="this.$topicPath === 'elections' || this.$topicPath === 'admin-state' || this.$topicPath == 'ai-deepfakes'">
     <div v-if="this.$topicPath === 'elections'" >
        <p class="intro-p">State election laws are changing. Keeping track of the latest developments in all 50 states can seem like an impossible job.</p>
        <p class="intro-p">Here's the solution: Ballotpedia's Election Administration Legislation Tracker.</p>
        <p class="intro-p">Ballotpedia's Election Administration Tracker sets the industry standard for ease of use, flexibility, and raw power. But that's just the beginning of what it can do:</p>
        <ul class="add-margin">
          <li>Ballotpedia's election experts provide daily updates on bills and other relevant political developments</li>
          <li>We translate bill text into summaries that are easy-to-digest everyday language</li>
          <li>And because it's from Ballotpedia, our Tracker is guaranteed to be neutral, unbiased, and non-partisan</li>
        </ul>
      </div>
      <div v-if="this.$topicPath === 'admin-state'" >
        <p class="intro-p">State laws relating to the administrative state are changing. Keeping track of the latest developments in all 50 states can seem like an impossible job.</p>
        <p class="intro-p">Here's the solution: Ballotpedia's {{this.$topicNameFull}} Legislation Tracker.</p>
        <p class="intro-p">Ballotpedia's {{this.$topicNameFull}} Tracker sets the industry standard for ease of use, flexibility, and raw power. But that's just the beginning of what it can do:</p>
        <ul class="add-margin">
          <li>Ballotpedia's policy experts provide daily updates on bills and other relevant political developments</li>
          <li>We translate bill text into summaries that are easy-to-digest everyday language</li>
          <li>And because it's from Ballotpedia, our Tracker is guaranteed to be neutral, unbiased, and non-partisan</li>
        </ul>
      </div>
      <div v-if="this.$topicPath === 'ai-deepfakes'" >
        <p class="intro-p"><i>Deepfakes</i> are videos, images, or audio files that have been generated or manipulated by artificial intelligence in order to realistically portray something that did not actually occur. According to the U.S. Government Accountability Office, “Deepfakes rely on artificial neural networks, which are computer systems modeled loosely on the human brain that recognize patterns in data. Developing a deepfake photo or video typically involves feeding hundreds or thousands of images into the artificial neural network, ‘training’ it to identify and reconstruct patterns — usually faces.”<sup>1</sup></p>
        <p class="intro-p">As legislatures across the country consider bills intended to address concerns about deepfake technology, Ballotpedia’s <b>AI deepfake legislation tracker</b> can help you follow the latest developments in all 50 states.</p>
        <p class="intro-p">There are no licenses or hidden fees, and no email address required to sign up.</p>
        <p>Put the power of Ballotpedia to work for you. Click <router-link to="search">here</router-link> to get started.</p>
      </div>
      
      <div class="cta-container">
        <router-link to="search"><button class="cta"><img src="../../src/assets/images/search-icon-white.svg"> Bill search<span class="small">Click here to search legislation </span></button></router-link>
        <router-link to="overview"><button class="cta"><img src="../../src/assets/images/overview-icon.svg" > Overview data<span class="small">Click here to see overview </span></button></router-link>
        <router-link to="faq"><button class="cta"><img src="../../src/assets/images/info-white.svg" > FAQ<span class="small">Click here to see our FAQ </span></button></router-link>
      </div>
    
      <div v-if="this.$topicPath != 'ai-deepfakes'">
        <p class="intro-p">And best of all - there are no licenses or hidden fees. No email address required to sign up.</p>
        <p class="intro-p">Now, all the information you need is just a mouse click away, including:</p>
        <div v-if="this.$topicPath === 'elections'">
          <ul class="add-margin">
            <li>Absentee/mail-in voting and early voting policies</li>
            <li>Ballot access requirements for candidates, parties, and ballot initiatives</li>
            <li>Election dates and deadlines</li>
            <li>Election oversight protocols</li>
            <li>In-person voting procedures</li>
            <li>Post-election procedures (including counting, canvassing, and auditing policies)</li>
            <li>Voter ID</li>
            <li>Voter registration and eligibility</li>
            <li>And more!</li>
          </ul>
        </div>
        <div v-else-if="this.$topicPath === 'admin-state'">
          <ul class="add-margin">
            <li>Regulation</li>
            <li>Adjudication and enforcement</li>
            <li>Legislative review</li>
            <li>Judicial review</li>
            <li>Agency structure and authority</li>
            <li>Rulemaking</li>
            <li>Federalism</li>
            <li>Executive authority</li>
            <li>And more!</li>
          </ul>
        </div>

        <p>Put the power of Ballotpedia to work for you. Click <router-link to="search">here</router-link> to get started.</p>

          <div v-if="this.$whatCountsListID !== null"  style="margin-top:40px; text-align: center; padding-bottom: 20px; padding-top: 10px; background-color:#364DA8;" class="subscribe-row">
          <div class="container">
            <form action="https://whatcounts.com/bin/listctrl" method="POST" style="margin-top: 0px;" class="subscribe-form">
            <input type="hidden" name="slid" :value="this.$whatCountsListID" />
            <input type="hidden" name="cmd" value="subscribe" />
            <input type="hidden" name="wxuiversionfirst" value="" />
            <input type="hidden" name="goto" :value="`https://legislation.ballotpedia.org/${this.$topicPath}/thank-you`" />
            <input type="hidden" name="errors_to" value="" />
            
            <input type="email" id="email" name="email" required maxlength="65" :placeholder='`Subscribe to our digest, "${this.$newsletterTitle}"`'
                oninvalid="this.setCustomValidity('Please enter a valid email address')"
                oninput="this.setCustomValidity('')"
                style="max-width: 90%; margin-right: 10px; font-size: 16px; width: 410px; height: 42px;"
                />
            <input type="hidden" id="format_mime" name="format" value="mime" />
            <input type="submit" class="btn btn-primary" value="Subscribe" style="width: 100px; height: 42px; font-size: 16px; font-weight: 600; background-color: #F0A142" />
          </form>
          <p style="color: #fff; text-align: center; font-weight:600; padding-top:15px; font-size:16px;">{{this.$newsletterSentence}}</p>
          </div>
        </div>
      </div>
      <div v-if="this.$topicPath === 'elections'" >
        <p class="subheader">About Ballotpedia's election policy content</p>
        Ballotpedia has extensive content about various aspects of election policy, including: 
        <ul>
          <li><a target="_blank" href="https://ballotpedia.org/Category:Election_governance">Election administration</a> and <a target="_blank" href="https://ballotpedia.org/Category:Voting_laws_by_state">voting policy</a></li>
          <li>Ballot access laws, for both <a href="https://ballotpedia.org/Ballot_access_for_major_and_minor_party_candidates" target="_blank">candidates</a> and <a href="https://ballotpedia.org/List_of_political_parties_in_the_United_States" target="_blank">political parties</a></li>
          <li><a href="https://ballotpedia.org/Electoral_systems_by_state" target="_blank">Electoral systems</a> (e.g., ranked-choice voting) and <a href="https://ballotpedia.org/Primary_election_systems_by_state" target="_blank">primary systems</a> (e.g., top-two primaries)</li>
          <li><a href="https://ballotpedia.org/Redistricting" target="_blank">Redistricting</a></li>
        </ul>

        To browse our election policy content, click <a href="https://ballotpedia.org/Election_Policy" target="_blank">here.</a> 
      </div>

      <div v-if="this.$topicPath === 'admin-state'" >
        <p class="subheader">About Ballotpedia's administrative state policy content</p>
        Ballotpedia has extensive content about various aspects of the administrative state, including: 
        <ul>
          <li><a target="_blank" href="https://ballotpedia.org/Five_pillars_of_the_administrative_state:_Nondelegation">Nondelegation</a></li>
          <li><a href="https://ballotpedia.org/Five_pillars_of_the_administrative_state:_Judicial_deference" target="_blank">Judicial deference</a></li>
          <li><a href="https://ballotpedia.org/Five_pillars_of_the_administrative_state:_Executive_control_of_agencies" target="_blank">Executive control of agencies</a></li>
          <li><a href="https://ballotpedia.org/Five_pillars_of_the_administrative_state:_Procedural_rights" target="_blank">Procedural rights</a></li>
          <li><a href="https://ballotpedia.org/Five_pillars_of_the_administrative_state:_Agency_dynamics" target="_blank">Agency dynamics</a></li>
        </ul>

        To browse our administrative state policy content, click <a href="https://ballotpedia.org/Administrative_State_Index" target="_blank">here.</a> 
      </div>
      <p class="subheader">FAQ</p>
      <p>Click <router-link to="faq">here</router-link> for detailed information about how to use this database.</p>
      
      <p class="subheader">Contact</p>
      <p>Questions about how to use this database? Are relevant bills missing? Please email us at <a href="mailto:editor@ballotpedia.org">editor@ballotpedia.org</a> and we'll be happy to assist you! </p>
      <br/>
      <p>Ballotpedia Media Contacts: <a href="mailto:media@ballotpedia.org">media@ballotpedia.org</a></p>
      <p class="subheader">About Ballotpedia</p>
      <p>Ballotpedia is the digital encyclopedia of American politics and elections. Our goal is to inform people about politics by providing accurate and objective information about politics at all levels of government.</p>
      
      <div v-if="this.$topicPath === 'ai-deepfakes'" class="footnotes">
      <!-- footnotes -->
     <hr>
      <ol>
        <li><i><a href="https://www.gao.gov/assets/gao-20-379sp.pdf" target="_blank">U.S. Government Accountability Office, “Science & Tech Spotlight: Deepfakes,” February 2020</a></i></li>
      </ol>
      </div>
  </div>

      <!-- China, ESG  -->
      <div v-if="this.$isBallotpediaTracker == false">
        <h3 class="text-center">{{ this.$topicNameFull }} legislation tracking.</h3>
            <div class="cta-container">
            <router-link to="search"><button class="cta custom-btn" ><img src="../../src/assets/images/search-icon-white.svg"> Bill search<span class="small">Click here to search legislation </span></button></router-link>
            <router-link to="overview"><button class="cta custom-btn" ><img src="../../src/assets/images/overview-icon.svg" > Overview data<span class="small">Click here to see overview </span></button></router-link>
          </div>
      </div>

  </div>
</template>


<script>
export default {
  name: 'Search',
  props: {
    
  },
  created() {
  }
}



</script>


<style>
.esg-btn {
  background-color: #BE1E2D !important;
}

.cta-container a {
  text-decoration: none!important;
}

.subscribe-form input {
  margin-bottom: 2px;
}

.footnotes{
  font-size: .8em;
}

.btn {
  padding: unset;
  background: #3047B1;
  color: white;
  border: none;
}

form input::placeholder {
  text-decoration: none !important;
  color: rgba(51, 51, 51, 0.693);
}

body {
  font-family: Montserrat;
  font-size: 18px;
}

.intro-p {
  margin-bottom: 10px;
}
p {
  margin: 0;
  padding: 0;
}

@media screen and (max-width: 800px) {
  .menu-nav-icon {
    padding-right: 5px;
  }
}

.cta-container {
  display: flex;
  justify-content: center;

}
.cta {
  display: block;
  color: white;
  width: 275px;
  background: #3047B1;
  border-radius: 5px;
  border: none;
  transition: .25s;
  margin-top: 60px;
  margin-bottom: 60px;
  font-size: 16px;
  /* display: inline-block; */
  font-size: 18px;
  margin-right: 20px;
  margin-left: 20px;
  padding: 15px 10px 15px 10px;
  font-weight: 600;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  /* background: white; */
  color: #444444;
  background: #f8f8f8;
  background: #EDEDED;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  background: #3852CA;
  color: white;

    /* background: #3047B1;
    color: white; */

}

.cta:hover {
  /* background: #162B8C; */
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.cta img {
  /* padding-right: 15px; */
  position: relative;
  right: 15px;
  height: 22px;
}

.small {
  display: block;
  font-size: 14px;
  font-weight: 400;
  display: none;
}

.home-container {
  color: #444444;
  margin-left: 15%;
  margin-right: 15%;
  margin-top: 125px;
}

.subscribe-row {
  margin-left: -21.5%;
  margin-right: -21.5%;
}

.header-logo {
  width: 400px;
  margin: 0 auto;
  display: block;
  margin-top: 20px;
  margin-bottom: 20px;
}

.home-tagline {
  text-align: center;
  font-weight: 400;
  line-height: 5px;
  margin-bottom: 30px;
  color: #9EA0A5;
}

.subheader {
  font-weight: 600;
  margin-top: 20px;
}

.add-margin {
  margin-top: 10px;
}

@media screen and (max-width: 1000px) {
  .home-container {
    color: #444444;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 100px;
  }

  .subscribe-row {
    margin-left: -30px;
    margin-right: -30px;
  }

}

@media screen and (max-width: 768px) {
  .home-container {
    color: #444444;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 75px;
  }

  .cta-container {
    flex-direction: column;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .cta {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    margin-left: 0;
  }

  .header-logo {
    width: 350px;
  }

  .home-tagline {
    font-size: 14px;
  }

  .subscribe-row {
    margin-left: -15px;
    margin-right: -15px;
  }

}

@media screen and (max-width: 368px) {
  .header-logo {
    width: 250px;
  }
}
</style>
